import React, { useState } from "react";
import "./WalletInfo.scss";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ERROR_TOAST, SUCCESS_TOAST, TOAST_OPTIONS } from "../../../../_utils/_toast";
import { useAppSelector } from "../../../../_utils/hooks";
import { addressFormate, formatBalance, formatValueFromWei, formatValueToWei } from "../../../../_utils/common";
import { contract } from "../../../../App";
import ModalTransfer from "../../../common/Modal/ModalTransfer";
import Input from "../../../common/Input/Input";
import { OperationsType } from "../../../../_utils/interfaces";
import { approveToken } from "../../../../servises/approveToken";

const WalletInfo = () => {
  const account = useAppSelector(state => state.account.address);
  const activePair = useAppSelector(state => state.pair.activePair);
  const balances = useAppSelector(state => state.pair.balances);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [tokenType, setTokenType] = useState("");
  const [isOperation, setIsOperation] = useState(false);
  const isTokenBuy = () => tokenType && tokenType === "B";

  const handleWithdraw = async (token: string, value: string) => {
    if (!contract) return;
    setIsOperation(true);
    const toastId =  toast.loading("Awaiting...", TOAST_OPTIONS);
    try {
      await contract.methods.withdraw(token, value).send({ from: account });
      toast.update(toastId, SUCCESS_TOAST);;
    } catch (err) {
      toast.update(toastId, ERROR_TOAST);
      console.log(err);
    }
    setIsOperation(false);
  }

  const handleDeposit = async () => {
    if (!contract || !Number(modalValue) || !activePair) return;
    setModal(false);
    setIsOperation(true);
    const token = isTokenBuy() ? activePair.baseToken : activePair.quoteToken;

    if (checkBalances() === OperationsType.approve) {
      const approveErr = await approveToken(modalValue, account, token);
      if (approveErr) {
        closeModal();
        return;
      }
    }

    const toastId =  toast.loading("Awaiting...", TOAST_OPTIONS);
    try {
      await contract.methods.deposit(token, formatValueToWei(modalValue)).send({ from: account });
      toast.update(toastId, SUCCESS_TOAST);
    } catch (err) {
      toast.update(toastId, ERROR_TOAST);
      console.log(err);
    } finally {
      closeModal();
    }
  }

  const checkBalances = () => {
    const value = formatValueToWei(modalValue);
    const allBalance = isTokenBuy() ? balances.baseBalance : balances.quoteBalance;
    const allowBalance = isTokenBuy() ? balances.baseAllowedBalance : balances.quoteAllowedBalance;

    if (Number(value) <= Number(allowBalance)) return OperationsType.deposit;
    if (Number(value) <= Number(allBalance)) return OperationsType.approve;
    return "";
  }

  const closeModal = () => {
    setModal(false);
    setIsOperation(false);
    setModalValue("");
    setTokenType("");
  }

  return (<>
    <div className="walletInfo_tab nondraggable_card">
      <div className="grid grid_balances">
        <h6 className="walletInfo_head">Wallet</h6>
        <h6 className="walletInfo_head"> {activePair.BaseSymbol} </h6>
        <h6 className="walletInfo_head"> {activePair.quoteSymbol} </h6>
        
        {account && 
          <>
            <span className="walletName">
              <CopyToClipboard text={account} onCopy={() => toast.success("Address copyed!", TOAST_OPTIONS)}>
                <p className="WalletIcon"></p>
              </CopyToClipboard>
              {addressFormate(account, 4)}
            </span>
            <span> {formatBalance(formatValueFromWei(balances.baseBalance))} </span>
            <span> {formatBalance(formatValueFromWei(balances.quoteBalance))} </span>
          </>
        }
      </div>

      {/* <div className="grid grid_approwed">
        <h6 className="walletInfo_head">Approwed</h6>
        <span className="walletInfo_head"></span>
        <span className="walletInfo_head"></span>

        <span> {activePair.BaseSymbol} </span>
        <span> {formatValueFromWei(balances.baseAllowedBalance)} </span>
        <span className="buttons_cont"></span>

        <span> {activePair.quoteSymbol} </span>
        <span> {formatValueFromWei(balances.quoteAllowedBalance)} </span>
        <span className="buttons_cont"></span> 
      </div> */}

      <div className="grid grid_locked">
        <span className="walletInfo_head"></span>
        <h6 className="walletInfo_head">Locked</h6>
        <h6 className="walletInfo_head">Available</h6>
        <span className="walletInfo_head"></span>

        <span> {activePair.BaseSymbol} </span>
        <span> {formatBalance(formatValueFromWei(balances.baseTokenLocked))} </span>
        <span> {formatBalance(formatValueFromWei(balances.baseTokenAvailable))} </span>
        <span className="buttons_cont">
          <button className="white_btn"
            disabled={isOperation || !Number(formatValueFromWei(balances.baseTokenAvailable))}
            onClick={() => {handleWithdraw(activePair.baseToken, balances.baseTokenAvailable)}}
          >
            Withdraw
          </button>

          <button className="white_btn"
            disabled={isOperation || !Number(formatValueFromWei(balances.baseBalance))}
            onClick={() => {setModal(true); setTokenType("B")}}
          >
            Deposit
          </button>
        </span>

        <span> {activePair.quoteSymbol} </span>
        <span> {formatBalance(formatValueFromWei(balances.quoteTokenLocked))} </span>
        <span> {formatBalance(formatValueFromWei(balances.quoteTokenAvailable))} </span>
        <span className="buttons_cont">
          <button className="white_btn" 
            disabled={isOperation || !Number(formatValueFromWei(balances.quoteTokenAvailable))} 
            onClick={() => {handleWithdraw(activePair.quoteToken, balances.quoteTokenAvailable)}}
          >
            Withdraw
          </button>

          <button className="white_btn" 
            disabled={isOperation || !Number(formatValueFromWei(balances.quoteBalance))} 
            onClick={() => {setModal(true); setTokenType("S")}}
          >
            Deposit
          </button>
        </span>
      </div>

    </div>

    <ModalTransfer 
      isOpen={modal}
      closeModal={closeModal}
      header={`Deposit ${isTokenBuy() ? activePair.BaseSymbol : activePair.quoteSymbol}`}
    >
    <div className='modal_input_cont'>
      <Input 
        value={modalValue}
        name={"Enter the number"} 
        balance={isTokenBuy() 
          ? formatValueFromWei(balances.quoteAllowedBalance) 
          : formatValueFromWei(balances.baseAllowedBalance)
        } 
        setValue={setModalValue}
      />
    </div>
    <button 
      className="main_button" 
      disabled={!Number(modalValue) || !checkBalances()}
      onClick={handleDeposit} 
    >
      {checkBalances() || "INSUFFICIENT BALANCE"}
    </button>
    </ModalTransfer>
  </>);
};

export default WalletInfo;
