import { Routes, Route } from "react-router-dom";
import Application from "../Application";
import CreatePair from "../components/pages/CreatePair/CreatePair";
import Faucet from "../components/pages/Faucet/Faucet";


const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/create" element={<CreatePair />} />
      <Route path="/faucet" element={<Faucet />} />
      <Route path="/*" element={ <Application />} />
    </Routes>
  );
};

export default PublicRoutes;