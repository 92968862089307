import React, { NavLink } from "react-router-dom";
import './Header.scss';
import Settings from "./Settings/Settings";
import { WalletButton } from "./ConnectWallet/WalletButton";
import classNames from "classnames";
import { useState } from "react";

const Header = () => {
  const [isOpen, setisOpen] = useState(false);
  const setActive = ({ isActive }) => isActive ? `link link_active` : 'link';
  return (
    <header className="App_header">
      <div className="flex_cont">
        <div className="logo"></div>
      <ul 
        className={classNames("header_navigation", { "burger_menu" : isOpen })} 
        onClick={() => setisOpen(false)}
      >
        <NavLink title="Trade" to='/' className={setActive}> Trade </NavLink>
        <NavLink title="Create Pair" to='/create' className={setActive}> Create pair </NavLink>
        <NavLink title="Faucet" to='/faucet' className={setActive}> Faucet </NavLink>
        <a className="link" href="https://twitter.com/shibexchange" target="blank"> Twitter </a>
        <a className="link" href="https://t.me/SHIBExchangeOfficial" target="blank"> Telegram </a>
      </ul>
      </div>
      <div className="flex_cont">
        <WalletButton />
        <Settings />
        <div className="burger_menu_bth" onClick={() => setisOpen(!isOpen)}></div>
      </div>
    </header>
  )
}

export default Header;
