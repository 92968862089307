import React, { useState } from 'react';
import { contract } from '../../../../App';
import { fixToDecimals, formatValueFromWei } from '../../../../_utils/common';
import classNames from 'classnames';
import './UserOrders.scss';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';
import { OrderFill, OrderInfo, OrderSideEnum, UserOrdersType } from '../../../../_utils/interfaces';
import { toast } from 'react-toastify';
import { ERROR_TOAST, SUCCESS_TOAST, TOAST_OPTIONS } from '../../../../_utils/_toast';
import { deleteOrder } from '../../../../redux/userOrdersSlice';
import { DECIMAL } from '../../../../_utils/constants';

interface Props {
  order: OrderInfo;
  index: number;
  activeTab: UserOrdersType;
}

const OrderRow = ({ order, index, activeTab }: Props) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.address);
  const pairs = useAppSelector(state => state.pair.pairs);
  const [expanded, setExpanded] = useState(null);

  const getPairName = (id: string) => {
    const pair = pairs.find(p => p.PairID === id);
    if (pair) {
      return `${pair.BaseSymbol}/${pair.quoteSymbol}`
    }
    return ""
  }

  const handleCancelOrder = async () => {
    if (!contract) return;
    const pair = pairs.find(p => p.PairID === order.PairId);
    if (pair && order.OrderID) {
      const toastId =  toast.loading("Awaiting...", TOAST_OPTIONS);
      try {
        await contract.methods.cancelOrder(pair?.baseToken, pair?.quoteToken, order.OrderID).send({ from: account });
        toast.update(toastId, SUCCESS_TOAST);
        dispatch(deleteOrder(order.OrderID));
      } catch (err) {
        toast.update(toastId, ERROR_TOAST);
        console.log(err)
      }
    }
  }

  const getOrderStatus = (status : "no" | "yes") => {
    if (status === 'yes') {
      return (!order.OrderFills.length) 
        ? "Cancelled" 
        : (Number(order.Volume)) ? "Partially filled" : "Filled";
    }
    if (!order.OrderFills.length) return "0.00%";
    const filledAmount = order.OrderFills.reduce((acc, fill) => acc + Number(formatValueFromWei(fill.Volume)), 0)
    return `${((filledAmount / (Number(formatValueFromWei(order.Volume)) + filledAmount)) * 100).toFixed(2)}%`
  }

  const getAmount = () => {
    let amount = Number(formatValueFromWei(order.Volume));
    if (!amount && order.OrderFills) {
      amount = order.OrderFills.reduce((acc, fill) => acc + Number(formatValueFromWei(fill.Volume)), 0)
    }
    return amount;
  }

  const getTotal = () => {
    let total = 0;
    if (order.OrderFills) {
      total = order.OrderFills.reduce((acc, fill) => {
        const p = formatValueFromWei(fill.Price)
        const v = formatValueFromWei(fill.Volume)
        return acc += (Number(p) * Number(v))
      }, 0)
    }
    return fixToDecimals(total, (DECIMAL + DECIMAL));
  }

  const getTradeTotal = (item: OrderFill) => {
    const p = formatValueFromWei(item.Price)
    const v = formatValueFromWei(item.Volume)
    return fixToDecimals((Number(p) * Number(v)), (DECIMAL + DECIMAL));
  }

  return (
    <>
    <tr 
      key={order.OrderID} 
      className={classNames({
        "red-text": order.Type === OrderSideEnum.S.toLowerCase(), 
        "green-text": order.Type === OrderSideEnum.B.toLowerCase(), 
        "odd-row": index % 2 === 1}
      )}
    >
      <td>
        <button
          disabled={!order.OrderFills.length}
          className={classNames("gray-button", 
            {"red-text": order.Type === OrderSideEnum.S.toLowerCase(), 
            "green-text": order.Type === OrderSideEnum.B.toLowerCase()}
          )}
          onClick={() => setExpanded(expanded === order.OrderID ? null : order.OrderID)}
        >
          {expanded === order.OrderID ? '-' : '+'}
        </button>
      </td>
      <td>{getOrderStatus(order.Completed)}</td>
      <td>{getPairName(order.PairId)}</td>
      <td>{order.Type}</td>
      <td>{formatValueFromWei(order.Price)}</td>
      <td>{getAmount()}</td>
      <td>
        {activeTab === UserOrdersType.open
          ? <button className='cancel_btn' onClick={handleCancelOrder}>x</button>
          : getTotal()
        }
      </td>
    </tr>
    {expanded && order.OrderFills.length && order.OrderFills.map((fill) =>
      <tr key={`${fill.Volume}-${fill.Price}-${fill.Timestamp}`}
        className={classNames({
          "red-text": order.Type === OrderSideEnum.S.toLowerCase(), 
          "green-text": order.Type === OrderSideEnum.B.toLowerCase(), 
          "odd-row": index % 2 === 1}
        )}
      >
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{formatValueFromWei(fill.Price)}</td>
        <td>{formatValueFromWei(fill.Volume)}</td>
        <td>{activeTab !== UserOrdersType.open && getTradeTotal(fill)}</td>
      </tr>
    )}
    </>
  );
}

export default OrderRow;
