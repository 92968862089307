import { contract } from "../../App";
import { formatToBars, tradeValueFormate } from "../../_utils/common";
import { EXCHANGE_NAME } from "../../_utils/constants";
import { Trade } from "../../_utils/interfaces";

const channelToSubscription = new Map();
const lastBarsCache = new Map();

const config = {
  supports_time: false,
  supported_resolutions: [
    "1",
    "5",
    "15",
    "30",
    "60",
    "120",
    "180",
    "240",
    "720",
    "1D",
    "W",
    "M",
  ],
};

export const dataFeedObject = (pairs: any[]) => {
  const dataFeed = {
    _pairs: pairs,

    onReady: (callback) => {
      setTimeout(() => callback(config));
    },

    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
      console.log('[searchSymbols]: Method call');
    },

    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      console.log("sfsfsdefe", symbolName)
      const pair = pairs.find((p) => p.pair_key === symbolName.replaceAll("/", "_").toLowerCase());
      if (!symbolName || !pair) {
        onResolveErrorCallback("cannot resolve symbol");
        return;
      }
      const selectedPairName = symbolName
        ?.replace("_", "/")
        ?.toUpperCase();

      if (symbolName.includes(":")){
        const idx = symbolName.indexOf(":");
        symbolName = symbolName.substring(idx + 1);
      }

      let symbolInfo = {
        name: selectedPairName,
        description: selectedPairName,
        type: "crypto",
        session: "24x7",
        timezone: "Etc/UTC",
        exchange: EXCHANGE_NAME,
        ticker: selectedPairName,
        minmov: 1,
        pricescale: 10 ** (pair.price_decimal ?? 6),
        has_intraday: true,
        intraday_multipliers: ["1", "60"],
        // intraday_multipliers: config.supported_resolutions,
        supported_resolutions: config.supported_resolutions,
        has_weekly_and_monthly: true,
        volume_precision: 8,
        data_status: "streaming",
        base_decimal: pair.base_decimal ?? 6,
        price_decimal: pair.price_decimal ?? 6,
      };

      setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
    },

    getBars: async (
      symbolInfo,
      resolution,
      from,
      onHistoryCallback,
      onErrorCallback
    ) => {
      if (!symbolInfo) {
        onHistoryCallback([], { noData: true });
        return;
      }

      const { name, base_decimal, price_decimal } = symbolInfo;
      // const pair = name?.replaceAll("/", "_").toLowerCase();

      // const bodyData = {
      //   resolution: resolution,
      //   symbol: pair,
      //   from: from.from,
      //   to: from.to,
      //   countBack: from.countBack
      // };

      // const searchParams = new URLSearchParams(bodyData).toString();
      // const url = `${API_HOST}/trading/trade/history_trading?${searchParams}`;
      // return axios
      //   .get(url)
      const activePair = pairs[0];
      const D = new Promise(() => contract.methods.getRecentTrades(activePair.baseToken, activePair.quoteToken).call()) as Promise<Trade[]>;
        D.then(( data ) => {
          if (!data) {
            onHistoryCallback([], { noData: true });
          }
          if (data) {
            const newData = formatToBars(data);
            const bars = [];
            for (let i = 0; i < newData?.t?.length; i++) {
              bars.push({
                time: Number(newData.t[i]) * 1000,
                low: tradeValueFormate(newData.l[i], price_decimal),
                high: tradeValueFormate(newData.h[i], price_decimal),
                open: tradeValueFormate(newData.o[i], price_decimal),
                close: tradeValueFormate(newData.c[i], price_decimal),
                volume: tradeValueFormate(newData.v[i], base_decimal),
              });
            }
console.log("baaars", bars)
            if (from.firstDataRequest) {
              lastBarsCache.set(name, { ...bars[bars.length - 1] });
            }

            if (bars?.length < 1) {
              onHistoryCallback([], { noData: true });
            } else {
              onHistoryCallback(bars, { noData: false });
            }
          }
        })
        .catch((err) => {
          onHistoryCallback([], { noData: true });
        });
    },

    subscribeBars: async (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
        subscribeBars(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscribeUID,
          onResetCacheNeededCallback,
          lastBarsCache.get(symbolInfo.name),
        );
    },

    unsubscribeBars: (subscribeUID) => {
      unsubscribeBars(subscribeUID);
    }
  };

  return dataFeed;
};

const createChannelString = (symbolInfo) => {
  var channel = symbolInfo?.name.split(/[:_/]/);
  const to = channel[1];
  const from = channel[0];
  return `0~${EXCHANGE_NAME}~${to}~${from}`;
};

const subscribeBars = async (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar
) => {
  const channelString = createChannelString(symbolInfo);

  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }

  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };

  channelToSubscription.set(channelString, subscriptionItem);
};

export const onGetChartData = (_data, symbol, pairs) => {
  const pair = pairs.find((p) => p.pair_key === symbol.replaceAll("/", "_").toLowerCase());
  const data = {
    sub_type: parseInt("0", 10),
    exchange: _data?.selectedPair,
    to: symbol.split("/")[1]?.toUpperCase(),
    from: symbol.split("/")[0]?.toUpperCase(),
    ts: _data?.time,
    volume: _data?.volume,
    price: _data?.close,
    close: _data?.close,
    open: _data?.open,
    high: _data?.high,
    low: _data?.low,
  };

  const tradePrice = tradeValueFormate(_data?.price, pair.price_decimal);
  const tradeTime = _data?.time;
  const tradeVolume = tradeValueFormate(_data?.volume, pair.base_decimal);

  const channelString = `0~${EXCHANGE_NAME}~${data?.to}~${data?.from}`;

  const subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem === undefined) {
    return;
  }

  const lastDailyBarData = subscriptionItem.lastDailyBar;
  const lastDailyBarTime = lastDailyBarData?.time || 0;

  if (!tradeTime && !lastDailyBarTime) {
    return;
  }

  const nextDailyBarTime = lastDailyBarTime / 10 ** 3;

  let bar;
  if (
    tradeTime > nextDailyBarTime ||
    Object.keys(lastDailyBarData).length === 0
  ) {
    bar = {
      time: tradeTime * 10 ** 3,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
      volume: tradeVolume,
    };
  } else {
    bar = {
      ...lastDailyBarData,
      high: Math.max(lastDailyBarData.high, tradePrice),
      low: Math.min(lastDailyBarData.low, tradePrice),
      close: tradePrice,
      volume: lastDailyBarData.volume + tradeVolume,
      time: nextDailyBarTime * 10 ** 3,
    };
  }

  subscriptionItem.lastDailyBar = bar;

  // send data to every subscriber of that symbol
  subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
};

const unsubscribeBars = (subscriberUID) => {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // unsubscribe from the channel, if it was the last handler
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
};
