import React from 'react';
import './MobileNavigation.scss';
import { NavLink } from 'react-router-dom';
import { saveChangeGridLayout, saveMobileTab } from '../../../redux/gridSlice';
import { useAppDispatch, useAppSelector } from '../../../_utils/hooks';
import { CUSTOMISE_VIEW_LIST } from '../ResponsiveLayout/grid';
import { recalcShowingComponent } from '../ResponsiveLayout/ResponsiveLayout';

const MobileNavigation = () => {
  const dispatch = useAppDispatch();
  const gridLayout = useAppSelector((state) => state.grid.grid_layout);
  const mobile_tab = useAppSelector((state) => state.grid.mobile_tab);
  const checkActive = (id: string) => (id === mobile_tab) ? `mobile_nav_item nav_active` : 'mobile_nav_item';

  const handleMobileTab = (type) => {
    dispatch(saveMobileTab(type));
    const newLayout = recalcShowingComponent(type, gridLayout);
    dispatch(saveChangeGridLayout(newLayout));
  }

  return (
    <div className="mobile_nav">
      <ul>
        {CUSTOMISE_VIEW_LIST.map((el) => (
          <NavLink to='/' 
            key={el.id} 
            title={el.id} 
            className={() => checkActive(el.id)} 
            onClick={() => handleMobileTab(el.id)}
          > 
            {el.title}
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default MobileNavigation;
