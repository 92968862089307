import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import Pairs from "../../pages/Trade/Pairs/Pairs";
import OrderBook from "../../pages/Trade/OrderBook/OrderBook";
import WalletInfo from "../../pages/Trade/WalletInfo/WalletInfo";
import TradeForm from "../../pages/Trade/TradeForm/TradeForm";
import UserOrders from "../../pages/Trade/UserOrders/UserOrders";
import Trades from "../../pages/Trade/Trades/Trades";
import { useAppDispatch, useAppSelector } from "../../../_utils/hooks";
import { saveChangeGridLayout, saveCurrentBrackpoint, saveExpandGridLayout, saveMobileTab, saveOldGridLayout } from "../../../redux/gridSlice";
import { Breakpoint, CardTitles, GRID_LAYOUT, GrigMobileFilter } from "./grid";
import AdjustmentTools from "./AdjustmentTools";
import './ResponsiveLayout.scss';
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import TradeChart from "../../Chart/Chart";
import { getGridFromToLocalStorage } from "../../../servises/localStorage.service";

const ReactGridLayout = WidthProvider(Responsive);

export const recalcShowingComponent = (tab: string, gridLayout: any) => {
  const viewport_height = window.innerHeight;
  let mobGrid = [];
  let newLayout = {
    lg: gridLayout.lg,
    md: gridLayout.md,
    sm: [],
    xs: []
  };

  switch (tab) {
    case GrigMobileFilter.market:
      mobGrid = [
        {
          i: GrigMobileFilter.market,
          x: 0,
          y: 0,
          h: (viewport_height - 395) / 30,
          w: 10,
          isResizable: false,
          isDraggable: false,
        }
      ];
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
    case GrigMobileFilter.chart:
      mobGrid = [
        {
          i: GrigMobileFilter.chart,
          x: 0,
          y: 0,
          h: (viewport_height - 395) / 30,
          w: 10,
          isResizable: false,
          isDraggable: false,
        }
      ];
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
    case GrigMobileFilter.wallet:
      mobGrid = [
        {
          i: GrigMobileFilter.wallet,
          x: 0,
          y: 0,
          h: (viewport_height - 200) / 30,
          w: 10,
          isResizable: false,
          isDraggable: false,
        }
      ];
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
    case GrigMobileFilter.userOrders:
      mobGrid = [
        {
          i: GrigMobileFilter.userOrders,
          x: 0,
          y: 0,
          h: (viewport_height - 395) / 30,
          w: 30,
          isResizable: false,
          isDraggable: false,
        }
      ];
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
    case GrigMobileFilter.orderBook:
      mobGrid = [
        {
          i: GrigMobileFilter.orderBook,
          x: 0,
          y: 0,
          h: (viewport_height - 395) / 30,
          w: 30,
          isResizable: false,
          isDraggable: false,
        }
      ];
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
    case GrigMobileFilter.trades:
      mobGrid = [
        {
          i: GrigMobileFilter.trades,
          x: 0,
          y: 0,
          h: (viewport_height - 395) / 30,
          w: 30,
          isResizable: false,
          isDraggable: false,
        }
      ];
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
    default:
      newLayout.sm = mobGrid;
      newLayout.xs = mobGrid;
      break;
  }
  return newLayout;
};

const ResponsiveLayout = ({orderChangeActive, orderChangeTrigger}) => {
  const dispatch = useAppDispatch();
  const gridLayout = useAppSelector((state) => state.grid.grid_layout);
  const oldGridLayout = useAppSelector((state) => state.grid.old_grid_layout);
  const lockLayout = useAppSelector((state) => state.grid.locked_layout);
  const mobile_tab = useAppSelector((state) => state.grid.mobile_tab);
  const current_breakpoint = useAppSelector(
    (state) => state.grid.current_breakpoint
  );
  const [isExpand, setIsExpand] = useState(false);
  const [isInitial, setIsInitial] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  let props = {
    className: "layout",
    isDraggable: true,
    isResizable: true,
    onLayoutChange: (arg: any, arg2: any) => { },
    breakpoints: { lg: 1480, md: 1020, sm: 768, xs: 480 },
    cols: { lg: 30, md: 30, sm: 1, xs: 1 },
    rowHeight: 20,
    draggableCancel: ".nondraggable_card",
    autoSize: true,
  };

  const components = {
    market: <Pairs />,
    wallet: <WalletInfo />,
    orderBook: <OrderBook />,
    buySellTab: <TradeForm />,
    trades: <Trades />,
    chart: <TradeChart />,
    userOrders: <UserOrders orderChangeActive={orderChangeActive} orderChangeTrigger={orderChangeTrigger}/>
  };

  useEffect(() => {
    if (oldGridLayout !== undefined && oldGridLayout !== null) {
      if (Object.keys(oldGridLayout).length > 0) {
        dispatch(saveChangeGridLayout(oldGridLayout));
        dispatch(saveOldGridLayout({}));
      }
    }
  }, []);

  const hanldeResetLayout = () => {
    dispatch(saveMobileTab(GrigMobileFilter.market));
    dispatch(saveChangeGridLayout(GRID_LAYOUT));
  };

  const onBreakpointChange = (breakpoint) => {
    if (breakpoint === Breakpoint.Large || breakpoint === Breakpoint.Medium) {
      if (isInitial || (current_breakpoint === Breakpoint.XSmall || current_breakpoint === Breakpoint.Small)) {
        hanldeResetLayout();
      }
    }
    if (current_breakpoint !== breakpoint) {
      dispatch(saveCurrentBrackpoint(breakpoint));
    }
    setIsInitial(true);
  };

  const onLayoutChange = (layout, layouts) => {
    if (!isExpand) {
      if (localStorage && firstLoad) {
        setFirstLoad(false);
        const lsGrid = getGridFromToLocalStorage() || {};
        if (Object.keys(lsGrid).length > 0) {
          dispatch(saveChangeGridLayout(lsGrid));
        }
      } else {
        if (current_breakpoint === Breakpoint.Large || current_breakpoint === Breakpoint.Medium) {
          dispatch(saveChangeGridLayout(layouts));
        } else {
          const newLayout = recalcShowingComponent(mobile_tab, gridLayout);
          dispatch(saveChangeGridLayout(newLayout));
        }
      }
    }
    props.onLayoutChange(layout, layouts);
  };

  const onRemoveItem = (i) => {
    let lg_grid_layout = gridLayout.lg.slice();
    let md_grid_layout = gridLayout.md.slice();
    let sm_grid_layout = gridLayout.sm.slice();
    let xs_grid_layout = gridLayout.xs.slice();

    const lg_index = lg_grid_layout.findIndex((doc) => doc.i === i);
    const md_index = md_grid_layout.findIndex((doc) => doc.i === i);
    const sm_index = sm_grid_layout.findIndex((doc) => doc.i === i);
    const xs_index = xs_grid_layout.findIndex((doc) => doc.i === i);

    lg_grid_layout.splice(lg_index, 1);
    md_grid_layout.splice(md_index, 1);
    sm_grid_layout.splice(sm_index, 1);
    xs_grid_layout.splice(xs_index, 1);

    let new_grid_layout = {
      lg: lg_grid_layout,
      md: md_grid_layout,
      sm: sm_grid_layout,
      xs: xs_grid_layout,
    };

    dispatch(saveChangeGridLayout( new_grid_layout));
  };

  const onExtendItem = (i) => {
    dispatch(saveOldGridLayout(gridLayout));

    setIsExpand(true);
    const newLayout = [
      {
        i: i,
        x: 0,
        y: 0,
        w: 23,
        h: 29,
      },
      {
        i: GrigMobileFilter.buySellTab,
        x: 23,
        y: 19,
        h: 9,
        w: 7,
        isResizable: false,
      },
    ];

    if (current_breakpoint === Breakpoint.Large || current_breakpoint === Breakpoint.Medium) {
      let currentLayout = { ...gridLayout };
      currentLayout[current_breakpoint] = newLayout;
      dispatch(saveExpandGridLayout(currentLayout));
    }
  };

  const onReduceItem = (i) => {
    setIsExpand(false);
    dispatch(saveChangeGridLayout(oldGridLayout));
    dispatch(saveOldGridLayout({}));
  };

  const generateDOM = () => {
    const layoutObject = gridLayout[current_breakpoint]?.reduce((acc, item) => {
      acc.push(
        <div key={item.i} className={`cardStyle ${item.i}`}>
            <div className={`card_header_style head_move ${lockLayout && 'locked'}`}>
              <div className="draggable_space">{CardTitles[item.i]}</div>
              {(current_breakpoint === Breakpoint.Large || current_breakpoint === Breakpoint.Medium) && ( 
                <AdjustmentTools
                  name={item.i}
                  onClose={onRemoveItem}
                  onExtend={onExtendItem}
                  onReduce={onReduceItem}
                />
              )}
            </div>
          <div className={`card_body_style`}>
            <ErrorBoundary>
              {components[item.i]}
            </ErrorBoundary>
          </div>
        </div>
      );

      return acc;
    }, []);
    return layoutObject;
  };

  return (
    <>
      <ReactGridLayout
        {...props}
        layouts={gridLayout}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
      >
        {generateDOM()}
      </ReactGridLayout>
    </>
  );
};

export default ResponsiveLayout;
