import React from 'react';
import { DECIMAL } from '../../../_utils/constants';
import './Input.scss';

interface Props {
  name: string;
  value: string;
  balance: string | number;
  setValue: (value: string) => void;
}

const Input = ({ name, value, balance, setValue }: Props) => {

  const handleInputChange = (e) => {
    let value = e.target.value as string;
    const regular = new RegExp('^\\d*(\\.\\d{0,' + DECIMAL + '})?$')
    if (value === ',' || value === '.') value = '0.'; 
    if (regular.test(value)) {
      // if (Number(value) > Number(balance)) {
      //   value = String(balance);
      // }
      setValue(value); 
    }
  };

  const handleIncrementDecrement = (operation: 'up' | 'down') => {
    const step = 1 / 10 ** DECIMAL;
    let newValue = 0;
    const currentValue = Number(value);
    
    if (operation === 'up') {
      if (currentValue === 0 || (currentValue < step)) {
        newValue = step;
      } else {
        newValue = currentValue + step;
      }
    }
    if (operation === 'down') {
      if (currentValue === 0 || (currentValue <= step)) {
        newValue = step;
      } else {
        newValue = currentValue - step;
      }
    }
    // if (newValue > Number(balance)) newValue = Number(balance);
    setValue(Number(newValue.toFixed(DECIMAL)).toString());
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      if (e.key === 'ArrowUp') {
        handleIncrementDecrement('up');
      }
      if (e.key === 'ArrowDown') {
        handleIncrementDecrement('down');
      }
    }
  }

  return (
    <div className='input_wrapper'>
      <input className='input'
        autoComplete="off"
        type="text" 
        step="any"
        placeholder={name}
        name={name} 
        value={value}
        onChange={handleInputChange} 
        onKeyDown={handleKeyPress}
      />
      <div className='input_btns'>
        <button className="trade_arrow_down trade_arrow_up" onClick={() => handleIncrementDecrement('up')}></button>
        <button className="trade_arrow_down" onClick={() => handleIncrementDecrement('down')}></button>
      </div>
    </div>
  );
};

export default Input;
