import { ToastOptions, UpdateOptions } from "react-toastify"

export const TOAST_OPTIONS: ToastOptions = {
  position: "top-center",
  theme: "dark"
}

export const DEPOSIT_TOAST: UpdateOptions = {
  render : "Deposit successfully! Create an order...",
  type: "info",
  isLoading: true
}

export const SUCCESS_TOAST: UpdateOptions = {
  render : "Success!",
  type: "success",
  isLoading: false,
  autoClose: 2000
}

export const ERROR_TOAST: UpdateOptions = {
  render : "Cancelled!",
  type: "error",
  isLoading: false,
  autoClose: 2000 
}