export const Breakpoint = {
  XSmall: 'xs',
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
};

export const GrigMobileFilter = {
  market: "market",
  orderBook: "orderBook",
  userOrders: "userOrders",
  wallet: "wallet",
  chart: "chart",
  trades: "trades",
  buySellTab: "buySellTab"
}

export enum CardTitles {
  market = "Trading Pairs",
  wallet = "Wallet Info",
  chart = "Chart",
  trades = "Trades",
  orderBook = "Order Book",
  userOrders = "User Orders",
}

export const GRID_LAYOUT = {
  lg: [
    {
      i: GrigMobileFilter.market,
      x: 0,
      y: 0,
      h: 17,
      w: 6,
      minW: 5,
      minH: 9,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.chart,
      x: 6,
      y: 0,
      h: 17,
      w: 10,
      minW: 7,
      minH: 14,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.orderBook,
      x: 16,
      y: 0,
      h: 17,
      w: 7,
      minW: 6,
      minH: 9,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.wallet,
      x: 24,
      y: 0,
      h: 12,
      w: 7,
      minW: 6,
      minH: 10,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.userOrders,
      x: 0,
      y: 5,
      h: 12,
      w: 16,
      minW: 10,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.trades,
      x: 16,
      y: 5,
      h: 12,
      w: 7,
      minW: 6,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.buySellTab,
      x: 23,
      y: 19,
      h: 9,
      w: 7,
      minW: 7,
      minH: 9,
      isResizable: false,
      resizeHandles: ["se"],
      isDraggable: true
    },
  ],

  md: [
    {
      i: GrigMobileFilter.market,
      x: 0,
      y: 0,
      h: 15,
      w: 6,
      minW: 5,
      minH: 9,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.chart,
      x: 6,
      y: 0,
      h: 15,
      w: 9,
      minW: 8,
      minH: 14,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.orderBook,
      x: 15,
      y: 0,
      h: 15,
      w: 7,
      minW: 6,
      minH: 9,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.wallet,
      x: 22,
      y: 0,
      h: 12,
      w: 8,
      minW: 8,
      minH: 10,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.userOrders,
      x: 0,
      y: 5,
      h: 10,
      w: 15,
      minW: 12,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.trades,
      x: 15,
      y: 5,
      h: 10,
      w: 7,
      minW: 6,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
      isDraggable: true
    },
    {
      i: GrigMobileFilter.buySellTab,
      x: 22,
      y: 2,
      h: 9,
      w: 7,
      minW: 7,
      minH: 9,
      isResizable: false,
      resizeHandles: ["se"],
      isDraggable: true
    },
  ],

  sm: [
    {
      i: GrigMobileFilter.market,
      x: 0,
      y: 0,
      h: 20,
      w: 30,
      isResizable: false,
      isDraggable: false,
    }
  ],

  xs: [
    {
      i: GrigMobileFilter.market,
      x: 0,
      y: 0,
      h: 20,
      w: 30,
      isResizable: false,
      isDraggable: false,
    }
  ],
};

export const CUSTOMISE_VIEW_LIST = [
  {
    id: GrigMobileFilter.market,
    title: CardTitles.market,
  },
  {
    id: GrigMobileFilter.chart,
    title: CardTitles.chart,
  },
  {
    id: GrigMobileFilter.orderBook,
    title: CardTitles.orderBook,
  },
  {
    id: GrigMobileFilter.userOrders,
    title: CardTitles.userOrders,
  },
  {
    id: GrigMobileFilter.trades,
    title: CardTitles.trades,
  },
  {
    id: GrigMobileFilter.wallet,
    title: CardTitles.wallet,
  },
];
