import React, { useState } from 'react';
import './ConnectWallet.scss';
import { useAppDispatch } from '../../../_utils/hooks';
import { saveAccountAddress } from '../../../redux/accountSlice';

export const ConnectWallet = () => {
  const dispatch = useAppDispatch();
  const [isConnecting, setIsConnecting] = useState(false);
  // const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);

  const handleConnectWallet = async () => {
    setIsConnecting(true);
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      dispatch(saveAccountAddress(accounts[0]));
    } catch (err) {
      console.log(err)
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <>
      { !window.ethereum
        ? <a href={`https://metamask.app.link/dapp/${window.location.hostname}/`} className='main_button connect_wallet'>
          Connect Wallet
        </a>
        : <button disabled={isConnecting} className='main_button connect_wallet' onClick={handleConnectWallet}>
          Connect Wallet
        </button>
      }
    </>
  );
};
