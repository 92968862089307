import React from 'react';
import './index.scss';
import Web3 from 'web3';
import PublicRoutes from './_utils/PublicRoutes';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/common/Header/Header';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './_utils/hooks';
import { saveAccountAddress } from './redux/accountSlice';
import { ToastContainer } from 'react-toastify';
import { saveActivePair, savePairs } from './redux/pairSlice';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './_utils/ABI';
import { FAUCET_ABI, FAUCET_ADDRESS } from './components/pages/Faucet/FaucetABI';

declare global {
	interface Window {
	  ethereum?: any;
	}
}
export const web3Instance = window.ethereum ? new Web3(window.ethereum) : null;
export const contract = web3Instance ? new web3Instance.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS) : null;
export const faucetContract = web3Instance ? new web3Instance.eth.Contract(FAUCET_ABI, FAUCET_ADDRESS) : null;

if (window.ethereum) window.ethereum.on('chainChanged', () => window.location.reload()); // track of the user's network chain ID

const App = () => {
  const dispatch = useAppDispatch();
  const activePairId = useAppSelector((state) => state.pair.activePairId);
	
  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const accounts = await web3Instance.eth.getAccounts();
        dispatch(saveAccountAddress(accounts[0]));
      }
    };
    initWeb3();
  }, []);  

  useEffect(() => {
    const fetchPairs = async () => {
      if (!contract) return;
      const result = await contract.methods.getPairs().call() as string;
      // console.log("PAIR RESULT:",result);
      if (Array.isArray(result)) {
        dispatch(savePairs(result))
      } else {
        try {
          const parsedResult = JSON.parse(result);
          if (Array.isArray(parsedResult)) {
            dispatch(savePairs(parsedResult))
            if (parsedResult.length && !activePairId) {
              dispatch(saveActivePair(parsedResult[0].PairID));
            }
          } else {
            console.error("The returned data is not an array:", parsedResult);
          }
        } catch (error) {
          console.error("Error parsing the returned data as JSON:", error);
        }
      }
    };

    fetchPairs();

    const interval = setInterval(fetchPairs, 60 * 1000);
    return () => clearInterval(interval);
  }, [activePairId]);

  return (
	<BrowserRouter>
		<div className="App">
      <ToastContainer />
			<Header />
			<div className="wrapper">
				<PublicRoutes />
			</div>
		</div>
	</BrowserRouter>
  );
}

export default App;
