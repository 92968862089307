import React from 'react';
import Modal from 'react-modal';
import './Modal.scss';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  header: string;
  children: JSX.Element[] | JSX.Element;
}

const ModalTransfer = ({ isOpen, closeModal, header, children }: Props) => {

  return (
    <Modal className="modal nondraggable_card" overlayClassName="modal_overlay nondraggable_card"
    isOpen={isOpen}
    onRequestClose={closeModal}
    ariaHideApp={false}
    >
    <div className="flex_cont">
      <h2 className="modal_header">{ header }</h2>
      <button onClick={closeModal}>X</button>
    </div>
    <div className="modal_body">
      {children}
    </div>
    </Modal>
  )
}

export default ModalTransfer;
