import { configureStore } from '@reduxjs/toolkit'
import accountSlice from './accountSlice'
import pairSlice from './pairSlice'
import userOrdersSlice from './userOrdersSlice'
import gridSlice from './gridSlice'

export const store = configureStore({
  reducer: {
    account: accountSlice,
    pair: pairSlice,
    userOrders: userOrdersSlice,
    grid: gridSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch