import React, { useEffect, useState } from 'react';
import './WalletButton.scss';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';
import { web3Instance } from '../../../../App';
import { saveAccountAddress } from '../../../../redux/accountSlice';
import { addressFormate, formatBalance, formatValueFromWei } from '../../../../_utils/common';
import { resetBalances } from '../../../../redux/pairSlice';
import { clearOrders } from '../../../../redux/userOrdersSlice';
import { ConnectWallet } from '../../ConnectWallet/ConnectWallet';

export const WalletButton = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.address);
  const [balance, setBalance] = useState("");

  const handleAccountsChanged = (accounts: Array<string>) => {
    if (accounts[0] !== account) {
      dispatch(resetBalances());
      dispatch(saveAccountAddress(accounts[0]));
    }
  }
  if (window.ethereum) window.ethereum.on('accountsChanged', handleAccountsChanged);

  const disconnectWallet = async () => {
    dispatch(saveAccountAddress(""));
    dispatch(resetBalances());
    dispatch(clearOrders());
  }

  const getBalance = async () => {
    if (!web3Instance || !account) return;
    try {
      const balance = await web3Instance.eth.getBalance(account);
      setBalance(formatValueFromWei(balance));
    } catch (err) {
      console.log(err);
    }
  };

	useEffect(() => {
    if (!account) return;
    const interval = setInterval(getBalance, 3000);
    return () => clearInterval(interval);
  }, [account]); 

  return (
    <>
      {account 
        ? (<div className='wallet_cont connect_wallet'>
          <p className='connect_wallet'>
            <span className='wallet_balance'>{formatBalance(balance)} BONE</span>
            <span className='wallet_address'>{addressFormate(account, 6)}</span>
            <button className="disconnect_wallet" onClick={disconnectWallet}></button>
          </p>
        </div> ) 
        :  <ConnectWallet />
      }
    </>
  );
};
