import React, { useEffect, useState } from "react";
import "./WidgetAssetCard.scss";
import { DECIMAL } from "../../../../_utils/constants";
import { Token } from "../../../../_utils/interfaces";
import { formatValueFromWei } from "../../../../_utils/common";
import { CONTRACT_ADDRESS } from "../../../../_utils/ABI";
import { useAppSelector } from "../../../../_utils/hooks";
import Input from "../../../common/Input/Input";
import { approveToken } from "../../../../servises/approveToken";
import classNames from "classnames";

interface Props {
  asset: Token;
  assetClick: () => void;
  value: string;
  setValue: (value: string) => void;
  disableButton?: (value: boolean) => void;
  disabled?: boolean;
}

const WidgetAssetCard = ({ asset, assetClick, value, setValue, disableButton = null, disabled = false }: Props) => {
  const account = useAppSelector((state) => state.account.address);
  const [ allowanceValue, setAllowanceValue ] = useState("");
  const assetName = asset.name || "TOKEN";
  const assetSymbol = asset.symbol || "SELECT";
  const assetBalance = Number(asset.balance) || 0;

  const handleMax = () => {
    if (!asset.tokenAddress) return;
    const newValue = Number(assetBalance.toFixed(DECIMAL));
    setValue(newValue.toString());
  };

  const getAllowedBalance = async () => {
    try {
      const allowanceValue = await asset.tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call();
      setAllowanceValue(formatValueFromWei(allowanceValue));
    } catch (err) {
      console.log(err);
    }
  }

  const handleApproveToken = async () => {
    await approveToken(value, account, asset.tokenAddress);
    getAllowedBalance();
    disableButton(false)
  }

  useEffect(() => {
    if (asset.tokenAddress && !disabled) getAllowedBalance();
  }, [asset]);

  useEffect(() => {
    if (disableButton === null) return;
    disableButton(Number(value) && Number(allowanceValue) < Number(value));
  }, [value, allowanceValue]);

  return (
    <div className='asset_wrapper'>

      <div className='asset_balance'>
        <span> Balance: </span>
        <span className='balance_cont'>
          {assetBalance}
          <button disabled={!Number(assetBalance)} className='red_btn' onClick={handleMax}>Set max</button>
        </span>
      </div>

      <div className='asset_item'>

        <button disabled={disabled} className='asset_name' onClick={assetClick}>
          <p className='asset_txt_big'>
            <span>
            {assetSymbol}
            </span>
            {!disabled  && <span className="arrow_down"></span>}
          </p>
          <p className='asset_txt_small'>
          {assetName}
          </p>
        </button>

        <div className='asset_choose'>
          { asset.tokenAddress
            ? 
            <Input name={'0.00'} value={value} balance={assetBalance} setValue={setValue}/>
            : <span className='choose_txt disabled'>{value || "0.00"}</span>
          }
        </div>
      </div>

      <div className={classNames('asset_balance', {"unvisible": disabled || !asset.tokenAddress})}>
        <span> Approved: </span>
        <span className='balance_cont'>
          {allowanceValue}
          <button disabled={!value || Number(asset.balance) < Number(value)} className='red_btn' onClick={handleApproveToken}>Approve</button>
        </span>
      </div>
    </div>
  );
};

export default WidgetAssetCard;
