import React, { useState, useEffect } from 'react';
import { contract } from '../../../../App';
import classNames from 'classnames';
import './UserOrders.scss';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';
import { OrderFilterType, OrderInfo, UserOrdersType } from '../../../../_utils/interfaces';
import OrderRow from './OrderRow';
import { getUsersOrders } from '../../../../servises/getUsersOrders';
import { saveHistoryOrders, saveOpenOrders, saveOrders } from '../../../../redux/userOrdersSlice';
import { getFilterFromLocalStorage, setFilterToLocalStorage } from '../../../../servises/localStorage.service';

const UserOrders = ({orderChangeTrigger, orderChangeActive}) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.address);
  const orders = useAppSelector((state) => state.userOrders);
  const activePair = useAppSelector((state) => state.pair.activePair);

  const [activeTab, setActiveTab] = useState(UserOrdersType.open);
  const [orderFilter, setOrderFilter] = useState(getFilterFromLocalStorage() || OrderFilterType.pair);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!account) return;
      const orders = await getUsersOrders(account);
      dispatch(saveOrders(orders));
    };

    fetchOrders();
  }, [account]);

  useEffect(() => {
    if (orderChangeTrigger) {
      const updateOrders = async () => {
        if (!contract || !account) return;
        const openOrdersData = await contract.methods.getOrdersByUser(account, true).call() as string;
        const orderHistoryData = await contract.methods.getOrdersByUser(account, false).call() as string;
        if (orderChangeActive) {
          dispatch(saveOpenOrders(JSON.parse(openOrdersData)));
        } else {
          dispatch(saveHistoryOrders(JSON.parse(orderHistoryData)));
        }
      };

      updateOrders();
    }
  }, [orderChangeTrigger, orderChangeActive, account]);

  const renderOrderRow = (orders: OrderInfo[]) => (orders.map((order, index) => (
    <OrderRow key={order.OrderID} order={order} index={index} activeTab={activeTab}/>
  )));

  const renderTitles = () => {
    return (activeTab === UserOrdersType.open)
      ? (<tr>
          <th></th>
          <th>Filled</th>
          <th>Pair</th>
          <th>Side</th>
          <th>Price</th>
          <th>Amount</th>
          <th></th>
        </tr>)
      : (<tr>
        <th></th>
        <th>Status</th>
        <th>Pair</th>
        <th>Side</th>
        <th>Price</th>
        <th>Amount</th>
        <th>Total</th>
      </tr>)
  }

  const handleOrderFilter = (type: OrderFilterType) => {
    setOrderFilter(type);
    setFilterToLocalStorage(type);
  }

  const getOrders = (orders: OrderInfo[]) => {
    if (orderFilter === OrderFilterType.pair) {
      const filterOrders = orders.filter(o => o.PairId === activePair.PairID);
      return filterOrders;
    }
    return orders;
  }

  return (
    <div className="userOrders_tab nondraggable_card">
      <p className='userOrders_nav'>
        <button 
          className={classNames({'active': activeTab === UserOrdersType.open})} 
          onClick={() => setActiveTab(UserOrdersType.open)}>
            Open Orders
        </button>
        <button 
          className={classNames({'active': activeTab === UserOrdersType.history})} 
          onClick={() => setActiveTab(UserOrdersType.history)}>
            History
        </button>
      </p>
      <div className="order_filter">
        <span 
          className={classNames({"active" : orderFilter === OrderFilterType.pair})} 
          onClick={() => handleOrderFilter(OrderFilterType.pair)}
        >
          {activePair.BaseSymbol}/{activePair.quoteSymbol}
        </span>
        <span 
          className={classNames({"active" : orderFilter === OrderFilterType.all})} 
          onClick={() => handleOrderFilter(OrderFilterType.all)}
        >
          All pairs
        </span>
      </div>
      <div className='orders_cont scroll'>
        <table>
          <thead>
            {renderTitles()}
          </thead>
          <tbody>
            {renderOrderRow(activeTab === UserOrdersType.open 
              ? getOrders(orders.openOrders) 
              : getOrders(orders.historyOrders)
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserOrders;
