export enum OrderSideEnum {
  B = "Buy",
  S = "Sell"
}

export enum TokenSideEnum {
  B = "base",
  Q = "quote"
}

export enum PairFilter {
  all = "all",
  fav = "fav"
}

export enum OrderFilterType {
  all = "all",
  pair = "pair"
}

export enum UserOrdersType {
  open = "open",
  history = "history"
}

export enum OperationsType {
  approve = "approve",
  deposit = "deposit"
}

export interface Token {
  symbol: string;
  name: string;
  decimals: string;
  balance: string;
  tokenAddress: string;
  tokenContract: any;
};

export interface Pair {
  PairID: string;
  BaseSymbol: string;
  quoteSymbol: string;
  baseToken: string;
  baseDecimals: string;
  quoteToken: string;
  quoteDecimals: string;
}

export interface Balances {
  baseTokenAvailable: string;
  baseTokenLocked: string;
  quoteTokenAvailable: string;
  quoteTokenLocked: string;
}

export interface PairBalances extends Balances {
  baseBalance: string;
  baseAllowedBalance: string;
  quoteBalance: string;
  quoteAllowedBalance: string;
}

export interface getOrders {
  buyOrderBook: Array<{
    orders: Array<number>;
    price: string;
  }>;
  sellOrderBook: Array<{
    orders: Array<number>;
    price: string;
  }>;
}

export interface OrderInfo {
  Completed: "no" | "yes";
  OrderID: string;
  Owner: string;
  PairId: string;
  Price: string;
  Type: string;
  Volume: string;
  OrderFills: Array<OrderFill>
}

export interface DepthOrders {
  Depth: string;
  Price: string;
  Volume: string;
}

export interface OrderFill {
  Volume: string;
  Price: string;
  Timestamp: string;
}

export interface Trade {
  price: string;
  timestamp: string;
  volume: string;
  isBuy: boolean;
}
