import Web3 from "web3";
import { CONTRACT_ADDRESS } from "../_utils/ABI";
import { TokenSideEnum } from "../_utils/interfaces";

export const tokenAbi = [
  {
    "constant": true,
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "name": "",
        "type": "string"
      }
    ],
    "payable": false,
    "type": "function"
  }, //name
  {
    "constant": true,
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "name": "",
        "type": "uint8"
      }
    ],
    "payable": false,
    "type": "function"
  }, //decimals (18)
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "type": "function"
  }, //balanceOf (in wei)
  {
    "constant": true,
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "name": "",
        "type": "string"
      }
    ],
    "payable": false,
    "type": "function"
  }, //symbol
  {
    "constant": false,
    "inputs": [
      {
        "name": "_spender",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "type": "function"
  }, //approve(contract_address, value(in wei)).send({ from: account }); - set value that i could spend
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      },
      {
        "name": "_spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "name": "remaining",
        "type": "uint256"
      }
    ],
    "type": "function"
  } //allowance(account, contract_address).call() - see value that i could spend (in wei)
] as any;

export const TOKENS = [
  { address: '0x60401dF2ce765c0Ac0cA0A76deC5F0a0B72f3Ae7', symbol: 'MOON' },
  { address: '0xD61496C12C2CAbF9e796268d2D05F15Ac558C38f', symbol: 'LMBO' },
  { address: '0x87cc055952C0b745C0F94089149b81A4f0F21Ed0', symbol: 'SHIB' },

  { address: '0x98A7DcB3C4064d84CAE8a5bCf456aCfb6AFbDA44', symbol: 'MOON' },
  { address: '0x090610B6d04957671A6fA0872aC06BD3Edfd7Fad', symbol: 'USDC' },
  { address: '0x95aD61954E133A755E47C79C74ba48D198EF500A', symbol: 'SHIB' },
];

export async function getAllTokenInfo(tokenAddress: string, accountAddress: string, web3: Web3) {
  const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  const balance = await tokenContract.methods.balanceOf(accountAddress).call();
  const name = await tokenContract.methods.name().call();
  const symbol = await tokenContract.methods.symbol().call();
  const decimals = await tokenContract.methods.decimals().call();
  const tokenInfo = { name, decimals, symbol, balance, tokenAddress, tokenContract };
  return tokenInfo;
}

export async function getTokenBalance(type: TokenSideEnum, tokenAddress: string, accountAddress: string, web3: Web3) {
  const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  const balance = await tokenContract.methods.balanceOf(accountAddress).call();
  const allowance = await tokenContract.methods.allowance(accountAddress, CONTRACT_ADDRESS).call();
  return {type, balance, allowance}
}

export async function getTokenName(tokenAddress: string, web3: Web3) {
  const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  const name = await tokenContract.methods.name().call();
  const symbol = await tokenContract.methods.symbol().call();
  return { name, symbol, tokenAddress}
}