import { contract } from "../App";

export const getUsersOrders = async (account: string) => {
  if (!contract || !account) return;
  const openOrdersData = await contract.methods.getOrdersByUser(account, true).call() as string;
  const orderHistoryData = await contract.methods.getOrdersByUser(account, false).call() as string;
  // console.log("OPEN", JSON.parse(openOrdersData))
  // console.log("HIST", JSON.parse(orderHistoryData))
  return {
    openOrders: JSON.parse(openOrdersData).reverse(),
    historyOrders: JSON.parse(orderHistoryData).reverse()
  };
};