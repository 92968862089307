import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { OrderInfo } from '../_utils/interfaces'

export interface OrdersState {
  openOrders: OrderInfo[];
  historyOrders: OrderInfo[];
}

const initialState: OrdersState = {
  openOrders: [],
  historyOrders: [],
}

export const accountSlice = createSlice({
  name: 'userOrders',
  initialState,
  reducers: {
    saveOrders: (state, action: PayloadAction<OrdersState>) => {
      state.openOrders = action.payload.openOrders
      state.historyOrders = action.payload.historyOrders
    },
    saveOpenOrders: (state, action: PayloadAction<OrderInfo[]>) => {
      state.openOrders = action.payload
    },
    deleteOrder: (state, action: PayloadAction<string>) => {
      const order = state.openOrders.find(or => or.OrderID === action.payload)
      state.openOrders = state.openOrders.filter(or => or.OrderID !== action.payload);
      state.historyOrders.unshift({...order, Completed: "yes"});
    },
    saveHistoryOrders: (state, action: PayloadAction<OrderInfo[]>) => {
      state.historyOrders = action.payload    
    },
    clearOrders: (state) => {
      state = initialState;
    }
  },
})

export const { saveOrders, saveOpenOrders, deleteOrder, saveHistoryOrders, clearOrders } = accountSlice.actions;

export default accountSlice.reducer;
