import React, { useState, useEffect } from 'react';
import Switch from "react-switch";
import "./OrderBook.scss";
import allMode from "../../../../assets/orderBook/allMode.svg";
import buyMode from "../../../../assets/orderBook/buyMode.svg";
import sellMode from "../../../../assets/orderBook/sellMode.svg";
import sideBySideMode from "../../../../assets/orderBook/sideBySideMode.svg";
import { DECIMAL, ORDER_BOOK_LENGTH } from '../../../../_utils/constants';
import { DepthOrders, OrderSideEnum, getOrders, OrderInfo } from '../../../../_utils/interfaces';
import classNames from 'classnames';
import { savePrice } from '../../../../redux/accountSlice';
import { getDepthFromLocalStorage, setDepthToLocalStorage } from '../../../../servises/localStorage.service';
import { contract } from '../../../../App';
import { fixToDecimals, formatValueFromWei, formatValueToWei } from '../../../../_utils/common';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';

const initOrders = {buyDepthArray: [], sellDepthArray: []};
const mods = [
  {
    mode: "ALL_V",
    img: allMode,
  },
  {
    mode: "BUY",
    img: buyMode,
  },
  {
    mode: "SELL",
    img: sellMode,
  },
  {
    mode: "ALL_H",
    img: sideBySideMode,
  },
];

const OrderBook = () => {
  const dispatch = useAppDispatch();
  const side = useAppSelector(state => state.account.orderSide);
  const activePair = useAppSelector(state => state.pair.activePair);
  const activePairId = useAppSelector(state => state.pair.activePairId);
  const lastPrice = useAppSelector(state => state.pair.lastPrice);

  const [depth, setDepth] = useState(getDepthFromLocalStorage());
  const [orders, setOrders] = useState(initOrders);
  const [isOpen, setIsOpen] = useState(false);
  const [activeMode, setActiveMode] = useState("ALL_V");
  const [isPriceInited, setIsPriceInited] = useState(false);

  const handleOnSelect = (mode: string) => setActiveMode(mode);

  const setPrice = (price: string) => dispatch(savePrice(price ? formatValueFromWei(price): ""));
  
  useEffect(() => {
    const fetchOrderbook = async () => {
      if (!contract || !activePairId) return;
      const orderBookData = await contract.methods.getOrderBooks(activePair.baseToken, activePair.quoteToken).call() as string;
      const orderBook = JSON.parse(orderBookData) as getOrders;
      // console.log(orderBook);
      const fetchedOrders = [] as Array<OrderInfo>;

      for (const orderType of ['buyOrderBook', 'sellOrderBook']) {
        for (const orderLevel of orderBook[orderType]) {
          for (const orderID of orderLevel.orders) {
            const orderInfo = await contract.methods.getOrderInfo(orderID).call() as string;
            // console.log(JSON.parse(orderInfo));
            fetchedOrders.push(JSON.parse(orderInfo));
          }
        }
      }
      const processedOrders = processOrders(fetchedOrders);
      setOrders(processedOrders);
    };

    fetchOrderbook();
    const interval = setInterval(fetchOrderbook, 5000);
    return () =>  clearInterval(interval);
  }, [activePairId]);

  const processOrders = (orders: OrderInfo[]) => {
    const buyOrders = [] as Array<OrderInfo>;
    const sellOrders = [] as Array<OrderInfo>;

    orders.forEach((order: OrderInfo) => {
      if (order.Type === OrderSideEnum.B.toLowerCase()) {
        buyOrders.push(order);
      } else {
        sellOrders.push(order);
      }
    });

    buyOrders.sort((a, b) => Number(b.Price) - Number(a.Price));
    sellOrders.sort((a, b) => Number(a.Price) - Number(b.Price));
    //console.log("SORTED BUYS: ",buyOrders);
    //console.log("SORTED SELLS: ",sellOrders);

    const buyDepthArray = calculateDepth(buyOrders, OrderSideEnum.B);
    const sellDepthArray = calculateDepth(sellOrders, OrderSideEnum.S);

    return { buyDepthArray, sellDepthArray };
  };

  const calculateDepth = (orders: Array<OrderInfo>, side: OrderSideEnum) => {
    let depth = 0;
    const depthArray = [] as Array<DepthOrders>;
    const ordersByPrice = {};
  
    for (const order of orders) {
      if (!ordersByPrice.hasOwnProperty(order.Price)) {
        ordersByPrice[order.Price] = { Volume: 0 };
      }
      ordersByPrice[order.Price].Volume += Number(formatValueFromWei(order.Volume));
    }
  
    const sortedPrices = Object.keys(ordersByPrice).sort((a, b) => Number(formatValueFromWei(a)) - Number(formatValueFromWei(b)));
  
    if (side === OrderSideEnum.B) {
      sortedPrices.reverse();
    }
  
    for (const price of sortedPrices) {
      depth += ordersByPrice[price].Volume;
      depthArray.push({ Price: String(price), Volume: formatValueToWei(ordersByPrice[price].Volume), Depth: formatValueToWei(fixToDecimals(depth, DECIMAL))});
    }
  
    if (side === OrderSideEnum.S) {
      depthArray.reverse();
    }
  
    return depthArray;
  };

  const handleDepthBar = (item: DepthOrders, totalD: string) => {
    return ((Number(item?.Depth) / Number(totalD)) * 100).toFixed(2);
  }

  const handleColorBackground = (item: DepthOrders, side: OrderSideEnum, totalD: string) => {
    const gradientColor = (side === OrderSideEnum.B)
      ? `linear-gradient(to ${
        activeMode === 'ALL_H' ? 'left' : 'right'
        }, var(--buy-background), var(--buy-background)`
      : 'linear-gradient(to right, var(--sell-background), var(--sell-background)';

    return `${gradientColor} ${handleDepthBar(item, totalD)}%, rgba(0, 0, 0, 0) ${handleDepthBar(item, totalD)}%)`;
  }

  const displayOrders = (depthArray: Array<DepthOrders>, side: OrderSideEnum) => {
    const totalD = side === OrderSideEnum.B ? depthArray[depthArray.length - 1]?.Depth : depthArray[0]?.Depth;

    return depthArray.slice(0, ORDER_BOOK_LENGTH).map((order, index) => {
      return (
        <tr key={index} 
          className={side === OrderSideEnum.B ? "green-row" : "red-row"}
          onClick={() => setPrice(order.Price)}
          style={{background: handleColorBackground(order, side, totalD)}}
        >
          <td className={side === OrderSideEnum.B ? "green-text" : "red-text"}> 
            {formatValueFromWei(String(order.Price))}
          </td>
          <td>
            {formatValueFromWei(String(order.Volume))}
          </td>
          <td className='text-end'> 
            {fixToDecimals(
              Number(formatValueFromWei(String(order.Volume))) 
              * Number(formatValueFromWei(String(order.Price))),
              DECIMAL + DECIMAL)}
          </td>
          {depth && <td style={{color: "var(--secondary-text"}} className='text-end'> 
            {formatValueFromWei(String(order.Depth))} 
          </td>}
        </tr>
      );
    });
  };

  const displayAllOrders = (sellOrders: Array<DepthOrders>, buyOrders: Array<DepthOrders>) => {
    const totalBD = buyOrders[buyOrders.length - 1]?.Depth;
    const totalSD = sellOrders[0]?.Depth;
    const ordersLength = (buyOrders.length >= sellOrders.length) ? buyOrders.length : sellOrders.length;
    const allOrders = [];

    for(let i = 0; i < ordersLength; i++) {
      allOrders.push([buyOrders[i] || {}, sellOrders[i] || {}])
    }

    return allOrders.slice(0, 40).map((order, index) => {
      const buyOrder = order[0];
      const sellOrder = order[1];
      return (
        <tr key={index}>
        { Object.keys(buyOrder).length
          ? <td colSpan={2} className="green-row" 
              onClick={() => setPrice(buyOrder.Price)}
              style={{background: handleColorBackground(buyOrder, OrderSideEnum.B, totalBD)}}
            > 
            <span>
              { formatValueFromWei(String(buyOrder.Volume)) }
            </span>
            <span className='green-text text-end'>
              { formatValueFromWei(String(buyOrder.Price)) }
              </span>
            </td>
          : <><td></td><td></td></>
        }
        { Object.keys(sellOrder).length
          ? <td colSpan={2} className="red-row"
              onClick={() => setPrice(sellOrder.Price)}
              style={{background: handleColorBackground(sellOrder, OrderSideEnum.S, totalSD)}}
            > 
            <span className='red-text'>
              { formatValueFromWei(String(sellOrder.Price)) }
            </span>
            <span className="text-end">
              { formatValueFromWei(String(sellOrder.Volume)) }
            </span>
          </td>
          : <><td></td><td></td></>
        }
        </tr>
      );
    });
  }

  const dispayTitle = () => {
    if (activeMode === "ALL_H") {
      return (
        <tr>
          <th>Amount</th>
          <th className='text-end'>Price</th>
          <th>Price</th>
          <th className='text-end'>Amount</th>
        </tr>
      )
    } else {
      return (
        <tr>
          <th>Price <span>{activePair.quoteSymbol}</span></th>
          <th>Amount <span>{activePair.BaseSymbol}</span></th>
          <th className='text-end'>Total</th>
          {depth && <th className='text-end'>Depth</th>}
        </tr>
      )
    }
  }

  useEffect(() => {
    if (!isPriceInited) {
      if (side === OrderSideEnum.B) {
        if (orders.sellDepthArray.length > 0) {
          setPrice(orders.sellDepthArray[orders.sellDepthArray.length - 1].Price);
          setIsPriceInited(true);
        } else {
          setPrice('');
        }
      } else {
        if (orders.buyDepthArray.length > 0) {
          setPrice(orders.buyDepthArray[0].Price);
          setIsPriceInited(true);
        } else {
          setPrice('');
        }
      }
    }
  }, [orders.sellDepthArray.length, orders.buyDepthArray.length, side, isPriceInited]);

  useEffect(() => setIsPriceInited(false), [side]);

  useEffect(() => {
    setOrders(initOrders);
    setIsPriceInited(false);
  }, [activePairId]);

  return (
    <div className="OrderBook_tab nondraggable_card">
      <div className='orderBook_options'>
        <button className="mode" onClick={() => setIsOpen(!isOpen)}>
          <img src={mods.find((mode) => mode.mode === activeMode)?.img} alt="icon"/>
          <span className={classNames('arrow_down', {'arrow_up': isOpen})} />
        </button>
        {isOpen &&
          <div className="more_mods">
            {mods.filter((mode) => mode.mode !== activeMode).map(({ mode, img }) => (
              <div key={img} data-name={mode}
                className={classNames('mode', {'active': mode === activeMode})}
                onClick={() => { 
                  handleOnSelect(mode);
                  setIsOpen(false)
                }}
              >
                <img src={img} alt="icon" />
              </div>
            ))}
          </div>
        }  
        <div className="customSwitch">
          <label>
            <Switch
              onChange={() => {
                setDepth(!depth);
                depth 
                  ? setDepthToLocalStorage("0")
                  : setDepthToLocalStorage("1")
              }}
              checked={depth}
              className={`react-switch`}
              height={9}
              width={17}
              borderRadius={6}
              onColor="#7143DC"
              offColor="#7F7F7F"
              disabled={activeMode === "ALL_H"}
            />
            <span>Depth</span>
          </label>
        </div>
      </div>

      { (activeMode === "ALL_V") 
        ? <>
          <div className="OrderBook_ALL_cont scroll">
            <table>
              <thead>
                {dispayTitle()}
              </thead>
              <tbody>{displayOrders(orders.sellDepthArray, OrderSideEnum.S)}</tbody>
            </table>
          </div>
          <div className='spase center_spase'>Last: {formatValueFromWei(lastPrice)}</div>
          <div className="OrderBook_ALL_cont scroll">
            <table>
              <tbody>{displayOrders(orders.buyDepthArray, OrderSideEnum.B)}</tbody>
            </table>
          </div>
        </>
      : <div className="OrderBook_cont scroll">
          <table>
            <thead>
              {dispayTitle()}
            </thead>
            <tbody>
              <tr> 
                <td colSpan={4} className='spase'>Last: {formatValueFromWei(lastPrice)}</td>
              </tr>
              {activeMode === "BUY" && displayOrders(orders.buyDepthArray, OrderSideEnum.B)}
              {activeMode === "SELL" && displayOrders(orders.sellDepthArray, OrderSideEnum.S)}
              {activeMode === "ALL_H" && displayAllOrders(orders.sellDepthArray, orders.buyDepthArray)}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};

export default OrderBook;
