import React, { useEffect, useState } from 'react';
import "./Faucet.scss";
import classNames from 'classnames';
import { toast } from "react-toastify";
import { ERROR_TOAST, SUCCESS_TOAST, TOAST_OPTIONS } from "../../../_utils/_toast";
import { useAppSelector } from '../../../_utils/hooks';
import { faucetContract, web3Instance } from '../../../App';
import { getTokenName } from '../../../servises/getTokenBalances';
import { ConnectWallet } from '../../common/ConnectWallet/ConnectWallet';

interface FAUCET_TOKEN {
  name: string;
  symbol: string;
  tokenAddress: string;
  balance: string;
}

const Faucet = () => {
  const account = useAppSelector((state) => state.account.address);
  const [tokenList, setTokenList] = useState<Array<FAUCET_TOKEN>>([]);
  const [selectTokens, setSelectTokens] = useState<Array<string>>([]);
  const [isTransfering, setIsTransfer] = useState(false);

  useEffect(() => {
    const getTokens = async () => {
      if (!faucetContract || !web3Instance) return;

      const tokenInfo = await faucetContract.methods.getBalances().call() as Array<Array<string>>;
      const tokensBalance = tokenInfo[0].reduce((acc, tt: string, ind: number) => {
        acc[tt] = tokenInfo[1][ind];
        return acc;
      }, {} as {[key: string]: string});
      const res = await Promise.all(tokenInfo[0].map(token => getTokenName(token, web3Instance)));
      const combinedTokenInfo = res.map(token => {return {...token, balance: tokensBalance[token.tokenAddress]}});
      console.log(combinedTokenInfo);
      setTokenList(combinedTokenInfo);
    }

    getTokens();
  }, []);

  const handleFundTransfer = async () => {
    if (!faucetContract || !account) return;
    
    setIsTransfer(true);
    const toastId =  toast.loading("Awaiting...", TOAST_OPTIONS);
    try {
      await faucetContract.methods.drip(selectTokens).send({ from: account});
      toast.update(toastId, SUCCESS_TOAST);
    } catch (err) {
      console.log(err);
      toast.update(toastId, ERROR_TOAST);
    } finally {
      setSelectTokens([]);
      setIsTransfer(false);
    }
  }

  const handleOnchange = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked);
    if (checked) {
      setSelectTokens([...selectTokens, name]);
    } else {
      setSelectTokens(selectTokens.filter((doc) => doc !== name));
    }
  };

  const isTokenChecked = (token: FAUCET_TOKEN) => !(selectTokens.findIndex((tt) =>Number(tt) === Number(token.tokenAddress)) === -1);
  const isTokenDisabled = (token: FAUCET_TOKEN) => !account || selectTokens.length >= 3 || !Number(token.balance);
  const isBtnDisabled = () => account && (!selectTokens.length || isTransfering);

  return (
    <div className='create_wrapper'>
      <div className='faucet_tab'>
        <h1 className='faucet_header'>Testnet Faucet</h1>
        <div className="select_coins scroll">
          <ul className="coin_list">
            {tokenList.map((token, index) => (
              <li key={index} className={classNames({"disabled": isTokenDisabled(token) && !isTokenChecked(token)})}>
                <input
                  id={token.tokenAddress}
                  name={token.tokenAddress}
                  type="checkbox"
                  className="faucet-input"
                  onChange={handleOnchange}
                  checked={isTokenChecked(token)}
                />
                <label htmlFor={token.tokenAddress} className="faucet-label">
                  {token?.symbol}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="faucet_rules">
          Faucet Rules: You can request each asset once per 24h per wallet account
        </div>
        {account
          ?  <button disabled={isBtnDisabled()} className="main_button faucet_btn" onClick={handleFundTransfer}>
              Transfer Funds
            </button>
          : <ConnectWallet />
        }
      </div>
    </div>
  )
}

export default Faucet;
