import { toast } from "react-toastify";
import { CONTRACT_ADDRESS } from "../_utils/ABI";
import { formatValueToWei } from "../_utils/common";
import { ERROR_TOAST, SUCCESS_TOAST, TOAST_OPTIONS } from "../_utils/_toast";
import { web3Instance } from "../App";
import { tokenAbi } from "./getTokenBalances";

//need small value
export const approveToken = async (value: string, account: string, token: string) => {
  if (!web3Instance) return;
  const tokenContract = new web3Instance.eth.Contract(tokenAbi, token);
  const toastId =  toast.loading("Awaiting...", TOAST_OPTIONS);
  try {
    await tokenContract.methods.approve(CONTRACT_ADDRESS, formatValueToWei(String(value))).send({ from: account });
    toast.update(toastId, SUCCESS_TOAST);;
  } catch (err) {
    toast.update(toastId, ERROR_TOAST);
    console.log(err);
    return err;
  }
}
