import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import './Range.scss';

const STEP = 1;
const MIN = 0;
const MAX = 100;

const RangeSlider = (props) => {
  const { rangeData, handelRange, disabled } = props;

  const [range, setRange] = useState([0]);

  const handelOnChange = (values) => {
    handelRange(values[0]);
  };

  useEffect(() => {
    if (rangeData) {
      if (rangeData > 100) {
        setRange([100]);
      } else if (rangeData < 0) {
        setRange([0]);
      } else {
        setRange([rangeData]);
      }
    } else {
      setRange([0]);
    }
  }, [rangeData]);

  return (
    <>
      <div
        className={"range_innr" + (disabled ? " disabled" : "")}
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Range
          disabled={disabled}
          values={range}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={handelOnChange}
          renderMark={({ props, index }) => (
            <div {...props}>
              {index % 25 === 0 ? (
                <div
                   className="tumbs"
                  style={{
                    height: "13px",
                    width: "13px",
                    borderRadius: "100px",
                    border: 
                      index * STEP < range[0]
                        ? "none"
                        : "2px solid var(--pair-active-row)",
                    background:
                      index * STEP < range[0]
                        ? "linear-gradient(106deg, var(--primary-1) 0%, var(--primary-2) 100%) 0% 0% no-repeat padding-box"
                        : "var(--module-background)",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      whiteSpace: "nowrap",
                      top: "23px",
                      fontSize: "9px",
                      color: "var(--primary-text)",
                    }}
                  >
                    {index * STEP}%
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          renderTrack={({ props, children, isDragged }) => (
            <div
              className="wrap_range"
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "6px",
                  width: "100%",
                  borderRadius: "10px",
                  background: getTrackBackground({
                    values: range,
                    colors: ["var(--primary-1)", "var(--pair-active-row)"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              className="transfrom-dot"
              {...props}
              style={{
                ...props.style,
                height: "13px",
                width: "13px",
                borderRadius: "100px",
                background:
                  "linear-gradient(106deg, var(--primary-1) 0%, var(--primary-2) 100%) 0% 0% no-repeat padding-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          )}
        />
      </div>
    </>
  );
};
export { RangeSlider };
