import React, { useState } from 'react';
import './Settings.scss';
import { CUSTOMISE_VIEW_LIST, GRID_LAYOUT, GrigMobileFilter } from '../../../common/ResponsiveLayout/grid';
import { saveChangeGridLayout, saveLockedLayout, saveMobileTab } from '../../../../redux/gridSlice';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';

const Settings = () => {
  const dispatch = useAppDispatch();
  const gridLayout = useAppSelector((state) => state.grid.grid_layout);
  const lockLayout = useAppSelector((state) => state.grid.locked_layout);
  const [openSettings, setOpenSettings] = useState(false);

  const handleCustomeView = async (event) => {
    const { name, checked } = event.target;

    let lg_grid_layout = gridLayout.lg.slice();
    let md_grid_layout = gridLayout.md.slice();
    let sm_grid_layout = gridLayout.sm.slice();
    let xs_grid_layout = gridLayout.xs.slice();

    if (checked) {
      let lg_item = GRID_LAYOUT.lg.find((doc) => doc.i === name);
      let md_item = GRID_LAYOUT.md.find((doc) => doc.i === name);

      if(lockLayout) {
        lg_item = {...lg_item, isResizable: false, isDraggable: false};
        md_item = {...md_item, isResizable: false, isDraggable: false};
      }

      lg_grid_layout.push(lg_item);
      md_grid_layout.push(md_item);

      let new_grid_layout = {
        lg: lg_grid_layout,
        md: md_grid_layout,
        sm: sm_grid_layout,
        xs: xs_grid_layout,
      };

      dispatch(saveChangeGridLayout(new_grid_layout));
    } else {
      const lg_index = lg_grid_layout.findIndex((doc) => doc.i === name);
      const md_index = md_grid_layout.findIndex((doc) => doc.i === name);

      lg_grid_layout.splice(lg_index, 1);
      md_grid_layout.splice(md_index, 1);

      let new_grid_layout = {
        lg: lg_grid_layout,
        md: md_grid_layout,
        sm: sm_grid_layout,
        xs: xs_grid_layout,
      };

      dispatch(saveChangeGridLayout(new_grid_layout));
    }
  };

  const handleLockLayout = (event) => {
    const isChecked = event.target.checked;
    let lg_grid_layout = gridLayout.lg.slice();
    let md_grid_layout = gridLayout.md.slice();
    let sm_grid_layout = gridLayout.sm.slice();
    let xs_grid_layout = gridLayout.xs.slice();
    
    const lg_grid_locked = lg_grid_layout.map((el) =>{ return {...el, isResizable: !isChecked, isDraggable: !isChecked}});
    const md_grid_locked = md_grid_layout.map((el) =>{ return {...el, isResizable: !isChecked, isDraggable: !isChecked}});

    let new_grid_layout = {
      lg: lg_grid_locked,
      md: md_grid_locked,
      sm: sm_grid_layout,
      xs: xs_grid_layout,
    };

    dispatch(saveChangeGridLayout(new_grid_layout));
    dispatch(saveLockedLayout(isChecked));
  }

  const hanldeResetLayout = () => {
    dispatch(saveMobileTab(GrigMobileFilter.market));
    dispatch(saveChangeGridLayout(GRID_LAYOUT));
    setOpenSettings(false);
    dispatch(saveLockedLayout(false));
  };
  
  return (
  <div className='setting_cont'>
    <button className="setting_btn" onClick={() => setOpenSettings(!openSettings)}>
      <span className="select_sett"></span>
    </button>

    <div className={openSettings ? "filter_show" : "filter_hide"}>
      <div className="filter_options">
        <p className='settings_title'>Customise view</p>
        <ul>
          {CUSTOMISE_VIEW_LIST.map((data, index) => (
            <li key={index}>
              <input
                type="checkbox"
                name={data.id}
                id={data.id}
                checked={gridLayout.lg.find((doc) => doc.i === data.id)
                  ? true
                  : false
                }
                onChange={(event) => handleCustomeView(event)}
              />
              <label htmlFor={data.id}>{data.title}</label>
            </li>
          ))}
        </ul>
        <div className="lock_cont">
          <input
            name="lock"
            id="lock"
            type="checkbox"
            onChange={(event) => handleLockLayout(event)}
            checked={lockLayout}
          />
          <label htmlFor="lock">Lock layout</label>
        </div>
        <div className="reset_btn text-center">
          <button className="layout_reset_btn" onClick={hanldeResetLayout}>
            Reset Layout
          </button>
        </div>
      </div>
    </div>
  </div>);
};

export default Settings;
