import React from "react";
import "./ErrorBoundary.scss";

interface IPropsErrorBoundary {
  pathname?: string,
  children: any,
}

interface IStateErrorBoundary {
  hasError: boolean,
  pathname: string,
}

export class ErrorBoundary extends React.Component <IPropsErrorBoundary, IStateErrorBoundary> {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      pathname: '',
    };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<IPropsErrorBoundary>, prevState: Readonly<IStateErrorBoundary>): void {
    if(this.props.pathname && this.props.pathname !== prevState.pathname) {
      this.setState({ hasError: false, pathname: this.props.pathname });
    }
  }

  render() {
    return(
      <>
        {(this.state.hasError) ? 
          <div className="error_boundary_msg">
            <p className="error_boundary_txt">oops, something went wrong</p>
          </div>
        : this.props.children
        }
      </>
    )
  }
}