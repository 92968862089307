import React, { useState, useEffect } from 'react';
import { contract } from '../../../../App';
import { formatValueFromWei } from '../../../../_utils/common';
import './Trades.scss';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';
import { Trade } from '../../../../_utils/interfaces';
import { saveLastPrice } from '../../../../redux/pairSlice';

const Trades = () => {
  const dispatch = useAppDispatch();
  const activePair = useAppSelector((state) => state.pair.activePair);
  const activePairId = useAppSelector((state) => state.pair.activePairId);
  const [allTrades, setAllTrades] = useState<Array<Trade>>([]);

  useEffect(() => {
    const fetchTrades = async () => {
      if (!contract || !activePairId) return;
      const tradesData = await contract.methods.getRecentTrades(activePair.baseToken, activePair.quoteToken).call();
      // console.log("TRAD", tradesData);
      setAllTrades([...tradesData].reverse());
      if (tradesData.length) dispatch(saveLastPrice(tradesData[tradesData.length - 1].price))
    };

    fetchTrades();
    const interval = setInterval(fetchTrades, 5000);
    return () =>  clearInterval(interval);
  }, [activePairId]);

  const renderTradeRow = () => (allTrades.map((tr, index) => (
    <tr 
      key={`${index}-${tr.timestamp}`} 
      className={tr.isBuy ? "green-row" : "red-row"}
    >
      <td className={tr.isBuy ? "green-text" : "red-text"}>
        {formatValueFromWei(tr.price)}
      </td>
      <td className='text-end'>{formatValueFromWei(tr.volume)}</td>
      <td className='text-end'>{convertTimestamp(tr.timestamp)}</td>
    </tr>
  )));

  const convertTimestamp = (t: string) => {
    const date = new Date(Number(t) * 1000);
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  return (
    <div className='trades_tab nondraggable_card'>
      <div className='trades_cont scroll'>
        <table>
          <thead>
            <tr>
              <th>Price <span>{activePair.quoteSymbol}</span></th>
              <th className='text-end'>Amount <span>{activePair.BaseSymbol}</span></th>
              <th className='text-end'>Time</th>
            </tr>
          </thead>
          <tbody>
            {renderTradeRow()}
          </tbody>
        </table>
      </div>
    </div>);
  }
  
  export default Trades;