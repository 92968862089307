import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { OrderSideEnum } from '../_utils/interfaces';

export interface CounterState {
  address: string;
  price: string;
  orderSide: OrderSideEnum;
}

const initialState: CounterState = {
  address: "",
  price: "",
  orderSide: OrderSideEnum.B
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    saveAccountAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload
    },
    savePrice: (state, action: PayloadAction<string>) => {
      state.price = action.payload
    },
    saveOrderSide: (state, action: PayloadAction<OrderSideEnum>) => {
      state.orderSide = action.payload
    },
  },
})

export const { saveAccountAddress, savePrice, saveOrderSide } = accountSlice.actions

export default accountSlice.reducer
