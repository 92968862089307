import React, { useEffect, useState } from "react";
import "./TotalBalance.scss";
import { useAppSelector } from "../../../../_utils/hooks";
import { OrderSideEnum } from '../../../../_utils/interfaces';
import { formatValueFromWei } from "../../../../_utils/common";

const TotalBalance = ({ side }) => {
  const pair = useAppSelector((state) => state.pair.activePair);
  const balances = useAppSelector((state) => state.pair.balances);
  const [balance, setBalance] = useState("0");

  const renderAvailableBalance = () => {
    let balance = "0";
    (side === OrderSideEnum.B)
      ? balance = formatValueFromWei(balances.quoteTokenAvailable)
      : balance = formatValueFromWei(balances.baseTokenAvailable)
    setBalance(balance);
  }

	// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => renderAvailableBalance, [side,balances]);

  return (
    <div className="total-balance-card">
      <span className="label"> Total Available for trading: </span>
      <span className="balance"> {balance} </span>
      <span className="currency">
        {side === OrderSideEnum.B ? pair?.quoteSymbol.toUpperCase() : pair.BaseSymbol.toUpperCase()}
      </span>
    </div>
  )
};

export default TotalBalance;