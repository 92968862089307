import React, { useEffect, useState } from "react";
import { contract, web3Instance } from "./App";
import { useAppDispatch, useAppSelector } from "./_utils/hooks";
import { getTokenBalance } from "./servises/getTokenBalances";
import { saveBalances } from "./redux/pairSlice";
import ResponsiveLayout from "./components/common/ResponsiveLayout/ResponsiveLayout";
import MobileNavigation from "./components/common/MobileNavigation/MobileNavigation";
import TradeForm from "./components/pages/Trade/TradeForm/TradeForm";
import { Breakpoint, GrigMobileFilter } from "./components/common/ResponsiveLayout/grid";
import { TokenSideEnum } from "./_utils/interfaces";

const Application = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.address);
  const activePair = useAppSelector((state) => state.pair.activePair);
  const activePairId = useAppSelector((state) => state.pair.activePairId);
  const current_breakpoint = useAppSelector((state) => state.grid.current_breakpoint);
  const mobile_tab = useAppSelector((state) => state.grid.mobile_tab);

  const [orderChangeTrigger, setOrderChangeTrigger] = useState(false);
  const [orderChangeActive, setOrderChangeActive] = useState(false);

	const getBalances = async () => {
    if (activePairId && web3Instance && contract) {
      const baseContract = activePair.baseToken;
      const quoteContract = activePair.quoteToken;
      const pr = [
        await contract.methods.getBalances(baseContract, quoteContract).call({ from: account }),
        await getTokenBalance(TokenSideEnum.B, baseContract, account, web3Instance),
        await getTokenBalance(TokenSideEnum.Q, quoteContract, account, web3Instance),
      ]

      const results = await Promise.all(pr);
      dispatch(saveBalances(results))
    }
	}

	useEffect(() => {
    if (!activePairId || !account) return;
    const interval = setInterval(getBalances, 3000);
    return () => clearInterval(interval);
  }, [activePairId, account]); 

  useEffect(() => {
    if (contract) {
      const subscription = contract.events.OrderChange()
        .on('data', (event) => {
          console.log('OrderPlaced event received:', event.returnValues);
          if (event.returnValues.user.toLowerCase() === account.toLowerCase()) {
            setOrderChangeActive(event.returnValues.isAdded);
            // Trigger an update in the UserOrders component
            setOrderChangeTrigger((prevTrigger) => !prevTrigger);
          }
        })
        .on('error', (error) => {
          console.error('Error in OrderPlaced event subscription:', error);
        });
      return () => subscription.unsubscribe();
    }
  }, []);
  
  const renderBuySellTab = () => {
    if ((current_breakpoint === Breakpoint.Small || current_breakpoint === Breakpoint.XSmall) && mobile_tab !== GrigMobileFilter.wallet) {
      return <TradeForm />;
    }
    return <></>;
  };

  return (
    <>
      {(current_breakpoint === Breakpoint.Small || current_breakpoint === Breakpoint.XSmall) && 
        <MobileNavigation />
      }
      <ResponsiveLayout orderChangeTrigger={orderChangeTrigger} orderChangeActive={orderChangeActive}/>
      {renderBuySellTab()}
    </>
  );
};
  
export default Application;
