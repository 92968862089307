import React, { useState, useEffect } from 'react';
import "./Pairs.scss";
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';
import { resetBalances, saveActivePair, saveFavoritePairs } from '../../../../redux/pairSlice';
import { Pair, PairFilter } from '../../../../_utils/interfaces';
import classNames from 'classnames';

const SORT_NAME = "BaseSymbol";

const PairList = () => {
  const dispatch = useAppDispatch();
  const pairs = useAppSelector(state => state.pair.pairs);
  const favPairs = useAppSelector(state => state.pair.favoritePairs);
  const activePair = useAppSelector(state => state.pair.activePair);

  const [search, setSearchValue] = useState("");
  const [filter, setFilter] = useState(PairFilter.all);
  const [sort, setSort] = useState('');
  const [pairsList, setPairsList] = useState<Array<Pair>>([]);

  const handleClick = (pairId: string) => {
    if(pairId === activePair.PairID) return;
    dispatch(saveActivePair(pairId));
    dispatch(resetBalances());
  };

  const handleFavorite = async (e: any, pairId: string) => {
    e.stopPropagation();
    if (filter === PairFilter.fav) {
      setPairsList(
        pairsList.filter((pair: Pair) => pair.PairID !== pairId)
      );
    }
    dispatch(saveFavoritePairs(pairId));
  };

  const handleSearch = async (value: string) => {
    setSearchValue(value);
    setFilter(PairFilter.all);
    setSort('');

    let newListOfPairs = searchInPairs(value);
    setPairsList(newListOfPairs);
  };

  const handleSelectMarket = (key: PairFilter) => {
    setSort('');
    setFilter(key);
    let newListOfPairs = [...pairs];
    setSearchValue("");

    if (key === PairFilter.fav) {
      newListOfPairs = [...favPairs];
    }
    setPairsList(newListOfPairs);
  };

  const handleToggleSort = (name: string) => {
    let pairLists: Pair[] = [...pairsList];
    let type = changeSortType(sort);
    setSort(type);

    if (search) {
      pairLists = searchInPairs(search);
    } else if (filter === PairFilter.fav) {
      pairLists = [...favPairs];
    }

    pairLists = sortListOfPairs(name, type, pairLists);
    setPairsList(pairLists);
  };

  const searchInPairs = (search: string): Array<Pair> => {
    const value = search.trim().toLowerCase();
    return value 
      ? pairs.filter((pair: Pair) => pair.BaseSymbol.toLowerCase().includes(value) || pair.quoteSymbol.toLowerCase().includes(value))
      : [...pairs]
  }

  const sortListOfPairs = (name: string, type: string, pairLists: Pair[]) => {
    if (type === "up") {
      pairLists.sort((a, b) => (a[name] > b[name] ? 1 : b[name] > a[name] ? -1 : 0));
    } else if (type === "down") {
      pairLists.sort((a, b) => (b[name] > a[name] ? 1 : a[name] > b[name] ? -1 : 0));
    } 
    return pairLists;
  }

  const changeSortType = (oldType: string) => {
    if (oldType === "up") return "down";
    if (oldType === "down") return "";
    return "up";
  }

  const isFavorite = (id: string) => favPairs.find((p: Pair) => p.PairID === id);

  useEffect(() => {
    let listOfPairs = [...pairs];
    if (search) {
      listOfPairs = searchInPairs(search);
    } else if (filter === "fav") {
      listOfPairs = [...favPairs];
    }
    if (sort) {
      listOfPairs = sortListOfPairs(SORT_NAME, sort, listOfPairs);
    }
    
    setPairsList(listOfPairs);
  }, [pairs]);

  return (
    <div className='pair_tab nondraggable_card'>
      <div className='search_cont'>
        <input
          type="text"
          placeholder={'Search pair'}
          aria-label="Search-input"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          className='search'
        />
      </div>

      <ul className='fltter_cont'>
        <li className='filter_item'>
          <button 
            className={filter === PairFilter.fav ? 'pair_fav_selected' : 'pair_fav'}
            onClick={() => handleSelectMarket(PairFilter.fav)}>
          </button>
        </li>
        <li className='filter_item'>
          <button
            className={classNames("filter", {'active': filter === PairFilter.all})}
            onClick={() => handleSelectMarket(PairFilter.all)}>
              ALL
          </button>
          </li>
      </ul>

      <div className='pair_cont'>
        <ul className='sort_cont'>
          <li>
            <button className='sort_item' onClick={() => handleToggleSort(SORT_NAME)}>
              Pair
              <span className='input_btns'>
                <span className={classNames('trade_arrow_down', 'trade_arrow_up', {'arrow_down_active': sort === "up"})} />
                <span className={classNames('trade_arrow_down', {'arrow_down_active': sort === "down"})} />
              </span>
            </button>
          </li>
        </ul>
        {pairsList.map((pair) => (
          <div
            className={classNames('pair_row', {'pair_row_active': pair.PairID === activePair.PairID})}
            key={pair.PairID}
            onClick={() => handleClick(pair.PairID)}
            style={{ cursor: 'pointer' }}
          >
            
            <button className={isFavorite(pair.PairID) ? 'pair_fav_selected' : 'pair_fav'} onClick={(e) => handleFavorite(e, pair.PairID)}></button>
            {" "}
            {pair.BaseSymbol}/{pair.quoteSymbol}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PairList;
