import { toast } from "react-toastify";
import { DEPOSIT_TOAST, ERROR_TOAST, SUCCESS_TOAST, TOAST_OPTIONS } from "../_utils/_toast";
import { contract, web3Instance } from "../App";
import { Balances } from "../_utils/interfaces";
import { ASSET_DECIMAL, DECIMAL } from "../_utils/constants";
import { fixToDecimals, formatValueFromWei, formatValueToWei } from "../_utils/common";

//need atomic value -> return big
const needToDeposit = async (isBuy: boolean, value: string, baseAssetTokenAddress: string, quoteAssetTokenAddress: string, account: string) => {
  try {
    const res = await contract.methods.getBalances(baseAssetTokenAddress, quoteAssetTokenAddress).call({ from: account });
    const balance = JSON.parse(res) as Balances;
    let avalibleBalance = isBuy ? balance.quoteTokenAvailable : balance.baseTokenAvailable;
    if (!Number(avalibleBalance)) return value;

    if (Number(value) >= 1 * (10 ** 21) && (Number(avalibleBalance) < Number(value))) {
      const smallValue = Number(formatValueFromWei(value));
      const smallBalance = Number(formatValueFromWei(avalibleBalance));
      console.log("ress ->", balance, "avalible ->", smallBalance, "want ->", smallValue, "need ->", formatValueToWei((smallValue - smallBalance).toFixed(ASSET_DECIMAL)));
      return formatValueToWei((smallValue - smallBalance).toFixed(ASSET_DECIMAL));
    }

    const needToDeposit = (Number(avalibleBalance) < Number(value)) ? Number(value) - Number(avalibleBalance) : "";
    console.log("ress ->>>", balance, "avalible ->", Number(avalibleBalance), "want ->", Number(value), "need ->", needToDeposit);
    return needToDeposit.toString();
  } catch (err) {
    throw err;
  }
}

//need small volume & price
export const createNewOrder = async (
  account: string, 
  volume: string, 
  price: string, 
  baseAssetTokenAddress: string, 
  quoteAssetTokenAddress: string, 
  isBuy: boolean
  ) => {

  if (!web3Instance || !contract) return;
  const toastId =  toast.loading("Awaiting...", TOAST_OPTIONS);
  const transferValue = (isBuy) 
    ? fixToDecimals((Number(price) * Number(volume)), (DECIMAL + DECIMAL))
    : volume;

  const priceAtomic = web3Instance.utils.toBN(formatValueToWei(price)).toString();
  const amountAtomic = web3Instance.utils.toBN(formatValueToWei(volume)).toString();
  const transferAtomic = formatValueToWei(transferValue);
  try {
    console.log(transferValue, "transferAtomic ->", transferAtomic)
    const depositValue = await needToDeposit(isBuy, transferAtomic, baseAssetTokenAddress, quoteAssetTokenAddress, account);

    if (depositValue) {
      console.log("we deposit ->", depositValue)
      const transferAddress = (isBuy) ? quoteAssetTokenAddress : baseAssetTokenAddress;
      await contract.methods.deposit(transferAddress, depositValue).send({ from: account });
      toast.update(toastId, DEPOSIT_TOAST);
    }
    
    console.log("creating", "price ->", priceAtomic, "amount ->", amountAtomic)
    await contract.methods.insertOrder(baseAssetTokenAddress, quoteAssetTokenAddress, priceAtomic, amountAtomic, isBuy).send({ from: account });
    toast.update(toastId, SUCCESS_TOAST);
  } catch (err) {
    toast.update(toastId, ERROR_TOAST);
    console.log("err ->", err);
  }
}