import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Pair, PairBalances } from '../_utils/interfaces'

export interface CounterState {
  pairs: Pair[];
  favoritePairs: Pair[];
  activePair: Pair;
  activePairId: string;
  lastPrice: string;
  balances: PairBalances;
}

const initialState: CounterState = {
  pairs: [],
  favoritePairs: [],
  activePair: {
    PairID: "",
    BaseSymbol: "",
    baseToken: "",
    baseDecimals: "",
    quoteSymbol: "",
    quoteToken: "",
    quoteDecimals: "",
  },
  activePairId: "",
  lastPrice: "",
  balances: {
    baseBalance: "",
    baseAllowedBalance: "",
    baseTokenAvailable: "",
    baseTokenLocked: "",
    quoteBalance: "",
    quoteAllowedBalance: "",
    quoteTokenAvailable: "",
    quoteTokenLocked: "",
  },
}

export const accountSlice = createSlice({
  name: 'pair',
  initialState,
  reducers: {
    savePairs: (state, action: PayloadAction<Pair[]>) => {
      state.pairs = action.payload
    },
    saveFavoritePairs: (state, action: PayloadAction<string>) => {
      const pairInd = state.favoritePairs.find((p: Pair) => p.PairID === action.payload);
      if (!pairInd) {
        const pair = state.pairs.find((p: Pair) => p.PairID === action.payload);
        if (pair) state.favoritePairs = [...state.favoritePairs, pair];
      } else {
        state.favoritePairs = state.favoritePairs.filter((pair: Pair) => pair.PairID !== action.payload);
      }
    },
    saveActivePair: (state, action: PayloadAction<string>) => {
      const active = state.pairs.find((pair) => pair.PairID === action.payload);
      if (active) {
        state.activePair = active;
        state.activePairId = action.payload;
      }      
    },
    saveBalances: (state, action: PayloadAction<Array<any>>) =>  {
      const balances = action.payload.reduce((acc, el) => {
        if(typeof(el) === "string") {
          const balance = JSON.parse(el, convertToString);
          const newAcc = {...acc, ...balance};
          return newAcc
        } else {
          const balance = {
            [`${el.type}Balance`]: el.balance,
            [`${el.type}AllowedBalance`]: el.allowance,
          }
          const newAcc = {...acc, ...balance};
          return newAcc
        }
      }, {} as PairBalances);
      // console.log("RR RR", balances)
      state.balances = balances;
    }, 
    resetBalances: (state) => {
      state.balances = initialState.balances;
    },
    saveLastPrice: (state, action: PayloadAction<string>) => {
      state.lastPrice = action.payload;
    },
  },
})

export const { 
  savePairs, 
  saveFavoritePairs, 
  saveActivePair, 
  saveBalances, 
  resetBalances, 
  saveLastPrice 
} = accountSlice.actions;

export default accountSlice.reducer;

const convertToString = (key, value) => (typeof value === 'number') ? value.toString() : value;
