import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GRID_LAYOUT, GrigMobileFilter } from '../components/common/ResponsiveLayout/grid';
import { setGridToToLocalStorage } from '../servises/localStorage.service';

interface PersistState {
  grid_layout: any;
  old_grid_layout: any;
  mobile_tab: string;
  current_breakpoint: string;
  locked_layout: boolean;
}

const initialState: PersistState = {
  grid_layout: GRID_LAYOUT,
  old_grid_layout: {},
  mobile_tab: GrigMobileFilter.market,
  current_breakpoint: "lg",
  locked_layout: false,
};

export const accountSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    saveChangeGridLayout: (state, action: PayloadAction<any>) => {
      if (localStorage) setGridToToLocalStorage(JSON.stringify(action.payload));
      state.grid_layout = action.payload
    },
    saveExpandGridLayout: (state, action: PayloadAction<any>) => {
      state.grid_layout = action.payload
    },
    saveOldGridLayout: (state, action: PayloadAction<any>) => {
      state.old_grid_layout = action.payload
    },
    saveMobileTab: (state, action: PayloadAction<string>) => {
      state.mobile_tab = action.payload    
    },
    saveCurrentBrackpoint: (state, action: PayloadAction<string>) => {
      state.current_breakpoint = action.payload    
    },
    saveLockedLayout: (state, action: PayloadAction<any>) => {
      state.locked_layout = action.payload    
    },
    resetLayout: (state) => {
      state = initialState;
    }
  },
})

export const { 
  saveChangeGridLayout,
  saveExpandGridLayout,
  saveOldGridLayout,
  saveMobileTab,
  saveCurrentBrackpoint,
  saveLockedLayout,
  resetLayout } = accountSlice.actions;

export default accountSlice.reducer;
