import React, { useState } from "react";
import './ResponsiveLayout.scss';
import { useAppSelector } from "../../../_utils/hooks";
import closeIc from "../../../assets/close.svg";
import extendIc from "../../../assets/extend.svg";

const AdjustmentTools = (props) => {
  const lockLayout = useAppSelector((state) => state.grid.locked_layout);
  const { name, onClose, onExtend, onReduce } = props;

  const [extend, setExtend] = useState(false);

  const handleOnClose = () => {
    onClose(name);
  };

  const handleOnExtend = () => {
    onExtend(name);
    setExtend(true);
  };

  const handleOnReduce = () => {
    onReduce(name);
    setExtend(false);
  };

  return (
    <>
    <ul className={`${lockLayout && 'locked'} card_adjustment_btn nondraggable_card`}>
      <li>
        <button data-test="zoom_view" className={extend ? "zoom_out" : "zoom_in"}
          onClick={() => {
            if (extend) {
              handleOnReduce();
            } else {
              handleOnExtend();
            }
          }}
        >
          {extend 
            ? ( <img src={extendIc} alt="icon" />) 
            : (<img src={extendIc} alt="icon" />)
          }
        </button>
      </li>
      {extend 
        ?("") 
        : (<li>
            <button onClick={handleOnClose} data-test="close_view">
              <img src={closeIc} alt="icon" />
            </button>
          </li>
        )}
    </ul>
    </>
  );
};

export default AdjustmentTools;
