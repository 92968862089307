import React, { useEffect, useState } from "react";
import './CreatePair.scss';
import WidgetAssetCard from "./WidgetAssetCard/WidgetAssetCard";
import { getAllTokenInfo } from "../../../servises/getTokenBalances";
import { Token, TokenSideEnum } from "../../../_utils/interfaces";
import { web3Instance } from "../../../App";
import { useAppSelector } from "../../../_utils/hooks";
import 'react-toastify/dist/ReactToastify.css';
import { approveToken } from "../../../servises/approveToken";
import SearchToken from "./SearchToken";
import { createNewOrder } from "../../../servises/createOrder";
import { ErrorBoundary } from "../../common/ErrorBoundary/ErrorBoundary";
import { formatValueFromWei } from "../../../_utils/common";
import { ConnectWallet } from "../../common/ConnectWallet/ConnectWallet";

export const initToken = {
  symbol: "",
  name: "",
  decimals: "",
  balance: "",
  tokenAddress: "",
  tokenContract: null,
}

const CreatePair = () => {
  const account = useAppSelector((state) => state.account.address);
  const pairs = useAppSelector((state) => state.pair.pairs);

  const [searchView, setSearchView] = useState<TokenSideEnum | null>(null);
  const [invalidPair, setInvalidPair] = useState(false);
  const [needApprove, setNeedApprove] = useState(false);

  const [buyToken, setBuyToken] = useState<Token>(initToken);
  const [quoteToken, setQuoteToken] = useState<Token>(initToken);
  const [buyValue, setBuyValue] = useState('');
  const [quoteValue, setQuoteValue] = useState('');

  useEffect(() => {
    const getTokens = async () => {
      if (web3Instance && account) {
        try {
          if (quoteToken.tokenAddress) {
            const res = await getAllTokenInfo(quoteToken.tokenAddress, account, web3Instance);
            setQuoteToken({
              ...res,
              balance: formatValueFromWei(res.balance)
            });
          }

          if (buyToken.tokenAddress) {
            const res = await getAllTokenInfo(buyToken.tokenAddress, account, web3Instance);
            setBuyToken({
              ...res,
              balance: formatValueFromWei(res.balance)
            })
          }
        } catch (err) {
          console.log(err)
        }
      }
    };
    getTokens();
  }, [account]);

  const createOrder = async () => {
    if (!account) return;

    if(needApprove) {
      await approveToken(buyValue, account, buyToken.tokenAddress);
      setNeedApprove(false);
      return;
    }
    const baseAssetTokenAddress = buyToken.tokenAddress;
    const quoteAssetTokenAddress = quoteToken.tokenAddress;

    await createNewOrder(account, buyValue, quoteValue, baseAssetTokenAddress, quoteAssetTokenAddress, false);
    setBuyValue('');
    setQuoteValue('');
  }

  const generateButtonText = () => {
    if (!buyToken.symbol || !quoteToken.symbol) return "CHOOSE TOKEN";
    if (buyToken.tokenAddress === quoteToken.tokenAddress) {
      if (!invalidPair) setInvalidPair(true);
      return `YOU CANT USE THE SAME TOKENS`;
    }
    const pairIsExist = pairs.findIndex(p => p.BaseSymbol === buyToken.symbol && p.quoteSymbol === quoteToken.symbol);
    if (pairIsExist !== -1) {
      if (!invalidPair) setInvalidPair(true);
      return `PAIR ALREADY EXIST`;
    } else if (invalidPair) {
      setInvalidPair(false);
    }
    if (!Number(buyToken.balance) || Number(buyToken.balance) < Number(buyValue)) return `INSUFFICIENT ${buyToken.symbol} BALANCE`;
    if (buyValue && quoteValue && needApprove) return `APPROVE ${buyToken.symbol}`;
    return `SELL ${buyToken.symbol} ${quoteToken.symbol}`
  }

  return (
    <div className='create_wrapper'>
      <div className='create_tab'>
      <ErrorBoundary>
        { searchView
          ? <SearchToken
              type={searchView}
              prevToken={searchView === (TokenSideEnum.B) ? buyToken : quoteToken} 
              setToken={searchView === (TokenSideEnum.B) ? setBuyToken : setQuoteToken} 
              closeSearch={() => setSearchView(null)} 
            />
          : <>
            <div className='create_header'>
              <span>Create New Pair</span>
            </div>
            {buyToken.symbol && 
              <div className="info_cont">
                <div className="info_text">
                  {`Please note that the approved balance must be greater than the amount you whant to spend.
                    If the allowed balance is not enough, use the "Approve" button to change the approved value`
                  }
                </div>
              </div>
            }
            <div className='assetcard_cont'>
              <WidgetAssetCard 
                asset={buyToken}
                assetClick={() => setSearchView(TokenSideEnum.B)}
                value={buyValue}
                setValue={setBuyValue}
                disableButton={setNeedApprove}
                disabled={!account}
              />
              <div className='switch'></div>
              <WidgetAssetCard
                asset={quoteToken}
                assetClick={() => setSearchView(TokenSideEnum.Q)}
                value={quoteValue}
                setValue={setQuoteValue}
                disableButton={setNeedApprove}
                disabled={!account}
              />
            </div>
            {account 
              ? <button 
                  disabled={account && (!buyToken.symbol || !quoteToken.symbol 
                    || !Number(buyToken.balance) || !Number(quoteToken.balance) 
                    || !Number(buyValue) || !Number(quoteValue) || invalidPair
                    || Number(buyToken.balance) < Number(buyValue))
                  }
                  className='main_button' 
                  onClick={createOrder}
                >
                  { generateButtonText() }
                </button>
              : <ConnectWallet />
            }
          </>
        }
      </ErrorBoundary>
      </div>
    </div>
  );
};
  
export default CreatePair;
  