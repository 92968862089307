// export const CONTRACT_ADDRESS = '0x51982223fEa35890cAB6f89Da001A66B77465e27'; // on Shibarium Mumbai
export const CONTRACT_ADDRESS = '0x855A3d7732e0C73Ae73CeA095914De2c86fA2774'; // on Shibarium Puppy
// export const CONTRACT_ADDRESS = '0x855A3d7732e0C73Ae73CeA095914De2c86fA2774'; // on BASE Testnet

export const CONTRACT_ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			}
		],
		"name": "addToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //addToken
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "baseAssetTokenAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "quoteAssetTokenAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "orderID",
				"type": "uint256"
			}
		],
		"name": "cancelOrder",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //cancelOrder
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //deposit
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "baseAssetTokenAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "quoteAssetTokenAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "volume",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "isBuy",
				"type": "bool"
			}
		],
		"name": "insertOrder",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //insertOrder
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "orderId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "volume",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "isBuy",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "pairId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "isAdded",
				"type": "bool"
			}
		],
		"name": "OrderChange",
		"type": "event"
	}, //OrderChange
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	}, //OwnershipTransferred
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			}
		],
		"name": "removeToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //removeToken
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //renounceOwnership
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //transferOwnership
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}, //withdraw
	{
		"inputs": [],
		"name": "getAllValidTokens",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getAllValidTokens
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "baseToken",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "quoteToken",
				"type": "address"
			}
		],
		"name": "getBalances",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getBalances
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "baseToken",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "quoteToken",
				"type": "address"
			}
		],
		"name": "getOrderBooks",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getOrderBooks
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "orderID",
				"type": "uint256"
			}
		],
		"name": "getOrderInfo",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getOrderInfo
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "activeOrders",
				"type": "bool"
			}
		],
		"name": "getOrdersByUser",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getOrdersByUser
	{
		"inputs": [],
		"name": "getPairs",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getPairs
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "baseAssetTokenAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "quoteAssetTokenAddress",
				"type": "address"
			}
		],
		"name": "getRecentTrades",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "volume",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "isBuy",
						"type": "bool"
					}
				],
				"internalType": "struct OnChainOrderBook.OrderFills[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //getRecentTrades
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //owner
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "validQuoteTokens",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}, //validQuoteTokens
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "validTokenList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	} //validTokenList
] as any;

// export const CONTRACT_ABI =	[
// {
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "tokenAddress",
// 				"type": "address"
// 			}
// 		],
// 		"name": "addToken",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "baseAssetTokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "quoteAssetTokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "orderID",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "cancelOrder",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "tokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "deposit",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "baseAssetTokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "quoteAssetTokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "price",
// 				"type": "uint256"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "volume",
// 				"type": "uint256"
// 			},
// 			{
// 				"internalType": "bool",
// 				"name": "isBuy",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "insertOrder",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "uint256",
// 				"name": "orderId",
// 				"type": "uint256"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "user",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "price",
// 				"type": "uint256"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "volume",
// 				"type": "uint256"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "bool",
// 				"name": "isBuy",
// 				"type": "bool"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "pairId",
// 				"type": "uint256"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "bool",
// 				"name": "isAdded",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "OrderChange",
// 		"type": "event"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "previousOwner",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "newOwner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "OwnershipTransferred",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "tokenAddress",
// 				"type": "address"
// 			}
// 		],
// 		"name": "removeToken",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "renounceOwnership",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "newOwner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "transferOwnership",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "tokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "withdraw",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "getAllValidTokens",
// 		"outputs": [
// 			{
// 				"internalType": "address[]",
// 				"name": "",
// 				"type": "address[]"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "baseToken",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "quoteToken",
// 				"type": "address"
// 			}
// 		],
// 		"name": "getBalances",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "baseToken",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "quoteToken",
// 				"type": "address"
// 			}
// 		],
// 		"name": "getOrderBooks",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "orderID",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "getOrderInfo",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "user",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "bool",
// 				"name": "activeOrders",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "getOrdersByUser",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "getPairs",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "baseAssetTokenAddress",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "quoteAssetTokenAddress",
// 				"type": "address"
// 			}
// 		],
// 		"name": "getRecentTrades",
// 		"outputs": [
// 			{
// 				"components": [
// 					{
// 						"internalType": "uint256",
// 						"name": "volume",
// 						"type": "uint256"
// 					},
// 					{
// 						"internalType": "uint256",
// 						"name": "price",
// 						"type": "uint256"
// 					},
// 					{
// 						"internalType": "uint256",
// 						"name": "timestamp",
// 						"type": "uint256"
// 					},
// 					{
// 						"internalType": "bool",
// 						"name": "isBuy",
// 						"type": "bool"
// 					}
// 				],
// 				"internalType": "struct OnChainOrderBook.OrderFills[]",
// 				"name": "",
// 				"type": "tuple[]"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "owner",
// 		"outputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"name": "validQuoteTokens",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "validTokenList",
// 		"outputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	}
// ] as any; //Shibarium Mumbai