import { OrderFilterType } from "../_utils/interfaces";

const keys = {
  grid: 'shib-grid',
  filter: 'shib-orders-filter',
  depth: 'shib-orders-depth',
};

export const setGridToToLocalStorage = ( data: string ) => localStorage.setItem(keys.grid, data);
export const getGridFromToLocalStorage = () => JSON.parse(localStorage.getItem(keys.grid));

export const setFilterToLocalStorage = ( data: string ) => localStorage.setItem(keys.filter, data);
export const getFilterFromLocalStorage = () => localStorage.getItem(keys.filter) as OrderFilterType;

export const setDepthToLocalStorage = ( data: string ) => localStorage.setItem(keys.depth, data);
export const getDepthFromLocalStorage = () => {
  const depth = localStorage.getItem(keys.depth);
  if (!depth) return true;
  return Boolean(Number(depth));
};
