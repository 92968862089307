import React, { useEffect, useState } from 'react';
import './CreatePair.scss';
import { getAllTokenInfo } from '../../../servises/getTokenBalances';
import { toast } from 'react-toastify';
import { contract, web3Instance } from '../../../App';
import { ERROR_TOAST, TOAST_OPTIONS } from '../../../_utils/_toast';
import { Token, TokenSideEnum } from '../../../_utils/interfaces';
import { useAppSelector } from '../../../_utils/hooks';
import { formatValueFromWei } from '../../../_utils/common';

interface Props {
  prevToken: Token;
  setToken: (token: Token) => void;
  closeSearch: () => void;
  type: TokenSideEnum | null;
}

const SearchToken = ({ prevToken, setToken, closeSearch, type }: Props) => {
  const account = useAppSelector((state) => state.account.address);
  const [search, setSearchValue] = useState<string>("");
  const [dropdown, setDropdown] = useState(false);
  const [validTokens, setValidTokens] = useState<Array<Token>>([]);
  const [displayedTokens, setDisplayedTokens] = useState<Array<Token>>([]);
  
  useEffect(() => {
    const getQuoteTokens = async () => {
      if (!web3Instance || !contract) return;
      const quoteTokens = await contract.methods.getAllValidTokens().call() as Array<string>;
      const results = await Promise.all(quoteTokens.map(token => getAllTokenInfo(token, account, web3Instance)));
      console.log("getAllTokenInfo", results);
      setValidTokens(results);
      setDisplayedTokens(results);
    }

    if(type === TokenSideEnum.Q) getQuoteTokens();
  }, []);

  const handleSearch = (v: string) => {
    setSearchValue(v);
    if (!v) {
      setDisplayedTokens(validTokens);
    }
    if (validTokens.length) {
      const newTokenList = validTokens.filter((el) => el.symbol.includes(v.toUpperCase()) || el.tokenAddress.includes(v));
      setDisplayedTokens(newTokenList);
    }
  }

  const chooseToken = (token: Token) => {
    setToken({
      ...token,
      balance: formatValueFromWei(token.balance)
    });
    setDropdown(false);
    closeSearch();
  }

  const selectToken = async (value: string) => {
    if (validTokens.length && type === TokenSideEnum.Q) {
      const finedToken = validTokens.find(el => el.tokenAddress === value.toUpperCase());
      if (finedToken) {
        console.log("f", finedToken)
        setToken({
          ...finedToken,
          balance: formatValueFromWei(finedToken.balance)
        })
        closeSearch();
      }
      return;
    }

    if (web3Instance && web3Instance.utils.isAddress(value) && value !== prevToken.tokenAddress) {
      const toastId =  toast.loading("Searching token...", TOAST_OPTIONS);
      try {
        const res = await getAllTokenInfo(search, account, web3Instance);
        console.log("getAllTokenInfo", res)

        if (res && ((prevToken.symbol && res.symbol !== prevToken.symbol) || !prevToken.symbol)) {
          setToken({
            ...res,
            balance: formatValueFromWei(res.balance)
          })
          closeSearch()
        }
        toast.dismiss(toastId);
      } catch (err) {
        toast.update(toastId, ERROR_TOAST);
        console.log(err)
      }
    }
  }

  return (
    <>
      <div className='create_header'>
        <button className='back_arrow' 
          onClick={() => {closeSearch(); setSearchValue("")}}
        />
        <span>Select {type} token</span>
      </div>
      <div className='search_cont'>
        <input
          type="text"
          placeholder={'Search token'}
          aria-label="Search-input"
          value={search}
          onFocus={() => {if(type === TokenSideEnum.Q) setDropdown(true)}}
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={(e) => selectToken(e.target.value)}
          className='search'
        />

        <div className={dropdown ? "assets_cont" : "filter_hide"}>
          <div className="filter_options">
            <ul>
              { displayedTokens.length
                ? displayedTokens.map((data, index) => (
                  <li key={index} onClick={() => chooseToken(data)}>
                    <span>{data.symbol}</span>
                    <span>{data.tokenAddress}</span>
                  </li>
                  ))
                : <span className='filter_err'> No tokens found... </span>
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchToken;
