import React, { useEffect, useState } from 'react';
import './TradeForm.scss';
import classNames from 'classnames';
import { OrderSideEnum } from '../../../../_utils/interfaces';
import Input from '../../../common/Input/Input';
import { DECIMAL } from '../../../../_utils/constants';
import { RangeSlider } from '../../../common/Range/Range';
import { useAppDispatch, useAppSelector } from '../../../../_utils/hooks';
import { createNewOrder } from '../../../../servises/createOrder';
import { saveOrders } from '../../../../redux/userOrdersSlice';
import { getUsersOrders } from '../../../../servises/getUsersOrders';
import TotalBalance from '../TotalBalance/TotalBalance';
import { saveOrderSide, savePrice } from '../../../../redux/accountSlice';
import ModalTransfer from '../../../common/Modal/ModalTransfer';
import { approveToken } from '../../../../servises/approveToken';
import { fixToDecimals, formatValueFromWei, formatValueToWei } from '../../../../_utils/common';

const TradeForm = () => {
  const dispatch = useAppDispatch();
  const activePair = useAppSelector(state => state.pair.activePair);
  const activePairId = useAppSelector(state => state.pair.activePairId);
  const account = useAppSelector((state) => state.account.address);
  const balances = useAppSelector(state => state.pair.balances);
  const price = useAppSelector((state) => state.account.price);

  const [tradeType, setTradeType] = useState("B");
  const [amount, setAmount] = useState('');
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [range, setRange] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [pendingOrder, setPendingOrder] = useState(false);

  // console.log("ACTIVEID: ",activePairId);
  // console.log("ACTIVE: ",activePair);

  const setActive = (key: string) => key === tradeType ? `nav-link active` : 'nav-link';

  const setPrice = (pr: string) => dispatch(savePrice(pr));

  const isOrderBuy = () => tradeType === "B";

  const changeSide = (type: string) => {
    setTradeType(type);
    setAmount("");
    dispatch(saveOrderSide(OrderSideEnum[type]));
    setRange(0);
    setInsufficientBalance(false);
  }

  const handleSubmit = async (e) => {
    if (!account || !activePairId) return;
    if(isNeedToApprove()) {
      const value = isOrderBuy() ? getTotal() : amount;
      setModal(true);
      setModalValue(value);
      return
    }

    const { baseToken: baseTokenAddress, quoteToken: quoteTokenAddress } = activePair;
    const isBuy = isOrderBuy();
    setPendingOrder(true);
    console.log(price, amount, isBuy)
    
    await createNewOrder(account, amount, price, baseTokenAddress, quoteTokenAddress, isBuy);
    setAmount("");
    dispatch(savePrice(""));
    setRange(0);
    setPendingOrder(false);
    setTimeout(async () => {
      const orders = await getUsersOrders(account);
      dispatch(saveOrders(orders));
    }, 5000);
  };

  const handleApproveButton = async () => {
    if (!modalValue) return;
    setModal(false);
    setModalValue("");
    const token = isOrderBuy() ? activePair.quoteToken : activePair.baseToken;
    await approveToken(modalValue, account, token);
  }

  const getTotal = () => {
    if (!price || !amount) return "0"
    return fixToDecimals(Number(price) * Number(amount), (DECIMAL + DECIMAL))
  };

  const handleMax = () => {
    let amount = isOrderBuy()
      ? String(Number(formatValueFromWei(balances.quoteTokenAvailable)) / Number(price))
      : formatValueFromWei(balances.baseTokenAvailable);
    
    if (amount) {
      setAmount(fixToDecimals(amount, DECIMAL));
      setRange(100);
    }
  };

  const handleNewRange = (range: number) => {
    const balance = isOrderBuy()
      ? formatValueFromWei(balances.quoteTokenAvailable)
      : formatValueFromWei(balances.baseTokenAvailable)

    if(!Number(balance)) return;
    let total = Number(((range * Number(balance)) / 100).toFixed(DECIMAL));
    let amount = "";

    if (isOrderBuy()) {
      amount = price ? String(total / Number(price)) : "";
    } else {
      amount = (total === 0) ? "" : String(total);
    }
    setAmount(amount);
    if (range >= 99) { 
      setRange(100)
    } else { 
      setRange(range) 
    }
  };

  const isDisabled = () => {
    if (!Number(price)) return true;
    const balance = isOrderBuy() ? balances.quoteTokenAvailable : balances.baseTokenAvailable;
    if (!Number(balance)) return true;
    return false;
  }

  const isNeedToApprove = () => {
    const allwBalance = isOrderBuy() ? balances.quoteAllowedBalance : balances.baseAllowedBalance;
    const avBalance = isOrderBuy() ? balances.quoteTokenAvailable : balances.baseTokenAvailable;
    const value = isOrderBuy() ? getTotal() : amount;
    return (Number(value) && Number(allwBalance) < (Number(formatValueToWei(value)) - Number(avBalance)))
  }

  const generateBtnText = () => {
    if (modal) return `${OrderSideEnum[tradeType].toUpperCase()} ${activePair.BaseSymbol}`;
    if (insufficientBalance) return `INSUFFICIENT ${isOrderBuy() ? activePair.quoteSymbol : activePair.BaseSymbol} BALANCE`;
    return isNeedToApprove()
      ? `Approve ${isOrderBuy() ? activePair.quoteSymbol : activePair.BaseSymbol}` 
      : `${OrderSideEnum[tradeType].toUpperCase()} ${activePair.BaseSymbol}`
  }

  useEffect(() => {
    // console.log(amount, price, modalValue)
    if (insufficientBalance) setInsufficientBalance(false);
    if (!Number(amount) || !Number(price)) return;
    let value = isOrderBuy() ? Number(price) * Number(amount) : Number(amount);
    if (modal && modalValue) value = Number(modalValue);

    const balance = isOrderBuy() 
      ? Number(formatValueFromWei(balances.quoteBalance)) + Number(formatValueFromWei(balances.quoteTokenAvailable)) 
      : Number(formatValueFromWei(balances.baseBalance)) + Number(formatValueFromWei(balances.baseTokenAvailable));
    if (value > balance) setInsufficientBalance(true);
  }, [amount, price, modalValue, balances.quoteTokenAvailable, balances.baseTokenAvailable])

  return (
  <>
    <div className={classNames('tradeForm_tab', {[isOrderBuy() ? "green-shadow" : "red-shadow"]: Number(price) && Number(amount)})}>
      <div className='tradeForm_side nondraggable_card'>
        <button id={OrderSideEnum.B} className={setActive("B")} onClick={() => changeSide("B")}>Buy</button>
        <button id={OrderSideEnum.S} className={setActive("S")} onClick={() => changeSide("S")}>Sell</button>
      </div>
      <div className='tradeForm_draggable'></div>
      {account
        ? <div className='tradeForm_form nondraggable_card'>
          <div className='tradeForm_inputs'>
            <div className="tradeForm_input_group">
              <span className='tradeForm_input_txt'>{activePair.BaseSymbol}</span>
              <Input 
                value={amount}
                name={"Amount"}
                balance={formatValueFromWei(balances.baseTokenAvailable)}
                setValue={setAmount}
              />
            </div>

            <span style={{display: "inline-block", width: "15px", marginRight: "8px"}}>
              <span className="slash"></span>
            </span>

            <div className="tradeForm_input_group">
              <Input
                value={price}
                name={"Price"} 
                balance={formatValueFromWei(balances.quoteTokenAvailable)} 
                setValue={setPrice}
              />
              <span className='tradeForm_input_txt'>{activePair.quoteSymbol}</span>
            </div>
          </div>

          <div className="tradeForm_info_cont">
            <button className='red_btn' onClick={handleMax} disabled={isDisabled()}>
              Set max
            </button>
            <div className="total_cont">
              {getTotal()}{" "}
              <span className="total_txt">Total</span>
            </div>
          </div>

          <RangeSlider
            disabled={isDisabled()}
            rangeData={range}
            handelRange={handleNewRange}
          />

          <button 
            disabled={!Number(price) || !Number(amount) || insufficientBalance || pendingOrder}
            className={classNames('main_button', {[isOrderBuy() ? "green-bd" : "red-bg"]: Number(price) && Number(amount)})}
            onClick={handleSubmit}
          >
            { generateBtnText() }
          </button>
        </div>
        : <div className="tradeForm_notification">Please Connect a Wallet to Begin Trading</div>
      }
    </div>
    <TotalBalance side={OrderSideEnum[tradeType]}/>

    <ModalTransfer 
      isOpen={modal}
      closeModal={() => {setModal(false); setModalValue("")}}
      header={isOrderBuy() ? `Approve ${activePair.quoteSymbol}` : `Approve ${activePair.BaseSymbol}`}
    >
      <div className="info_cont">
        <div className="info_text">
          {`You can approve more to avoid this operation before each order`}
        </div>
      </div>
      <div className='modal_input_cont'>
        <Input 
          value={modalValue}
          name={"Enter the number"} 
          balance={isOrderBuy() 
            ? formatValueFromWei(balances.quoteBalance) 
            : formatValueFromWei(balances.baseBalance)
          } 
          setValue={setModalValue}
        />
      </div>
      <button 
        className="main_button" 
        disabled={!Number(modalValue) || insufficientBalance}
        onClick={handleApproveButton} 
      >
        {insufficientBalance ? "INSUFFICIENT BALANCE" : "Approve"}
      </button>
    </ModalTransfer>

  </>);
}

export default TradeForm;
