// import TradingChartComponent from "./index.jsx";
import React, { useEffect, useRef } from 'react';
import { useAppSelector } from "../../_utils/hooks";
import { ResolutionString, IChartingLibraryWidget } from "../../_utils/charting_library";
import { dataFeedObject } from "./datafeed";

let tvScriptLoadingPromise;

function TradeChart() {
  const pairs = useAppSelector((state) => state.pair.pairs);
  const activePair = useAppSelector((state) => state.pair.activePair);
  const onLoadScriptRef = useRef() as React.MutableRefObject<IChartingLibraryWidget>;
  const background = "rgba(19, 23, 34, 1)";
  const buy = "rgba(82, 164, 154, 1)";
  const sell = "rgba(221, 94, 86, 1)";
  const gray = "rgba(127, 127, 127, 1)";

  useEffect(() => {
    // @ts-ignore
    onLoadScriptRef.current = createWidget;
    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;
        document.head.appendChild(script);
      });
    }
    // @ts-ignore
    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
    return () => onLoadScriptRef.current = null;

    function createWidget() {
      if (document.getElementById('technical-analysis-chart-demo') && 'TradingView' in window) {
        return new window.TradingView.widget({
          container: "technical-analysis-chart-demo",
          container_id: "technical-analysis-chart-demo",
          library_path: "/charting_library/",
          autosize: true,
          symbol: `${activePair.BaseSymbol}/${activePair.quoteSymbol}`,
          interval: "5" as ResolutionString,
          timezone: "exchange",
          theme: "Dark",
          toolbar_bg: background,
          locale: "en",
          datafeed: dataFeedObject(pairs),
          disabled_features: [
            "volume_force_overlay",
            "use_localstorage_for_settings",
            "header_symbol_search",
            "header_compare",
            "header_saveload",
            "display_market_status",
          ],
          enabled_features: ["show_animated_logo", "hide_left_toolbar_by_default"],
          overrides: {
            "mainSeriesProperties.showCountdown": false,
            "paneProperties.background": background,
            "symbolWatermarkProperties.transparency": 90,
            "scalesProperties.textColor": gray,
            "scalesProperties.bgColor": gray,
            "scalesProperties.fontSize": 11,
            "paneProperties.topMargin": 15,
            "mainSeriesProperties.candleStyle.upColor": buy,
            "mainSeriesProperties.candleStyle.downColor": sell,
            "mainSeriesProperties.candleStyle.wickUpColor": buy,
            "mainSeriesProperties.candleStyle.wickDownColor": sell,
            "mainSeriesProperties.candleStyle.borderColor": buy,
            "mainSeriesProperties.candleStyle.borderUpColor": buy,
            "mainSeriesProperties.candleStyle.borderDownColor": sell,
            "mainSeriesProperties.barStyle.upColor": buy,
            "mainSeriesProperties.barStyle.downColor": sell,
            "mainSeriesProperties.barStyle.dontDrawOpen": true,
          },
          custom_css_url: `../../_utils/charting_library/static/css/header.css`,
        });
      }
    }}, [activePair.PairID]);

  return (
    <div className='tradingview-widget-container nondraggable_card' style={{width: "100%", height:"100%"}}>
      <div id='technical-analysis-chart-demo' style={{width: "100%", height:"100%"}} />
        <div className="tradingview-widget-copyright">
      </div>
    </div>
  );
}

// const TradeChart = () => {
//   const tradeData = [];
//   const pairs = useAppSelector((state) => state.pair.pairs);
//   const activePair = useAppSelector((state) => state.pair.activePair);
//   const activePairId = useAppSelector((state) => state.pair.activePairId);
//   const [showChart, setShowChart] = useState(true); ///false

//   useEffect(() => {
//     if (activePairId) {
//       const pair = pairs.find((p) => p.PairID === activePair.PairID);
//       if (pair) {
//         setShowChart(true);
//       }
//     }
//   }, [activePairId, pairs])

//   return (
//     <>
//       <div className="nondraggable_card">
//         {showChart && (
//           <TradingChartComponent
//             background = "rgba(19, 23, 34, 1)"
//             buy = "rgba(82, 164, 154, 1)"
//             sell = "rgba(221, 94, 86, 1)"
//             gray = "rgba(127, 127, 127, 1)"
//             interval="5"
//             symbol={`${activePair.BaseSymbol}/${activePair.quoteSymbol}`}
//             kline={tradeData}
//             pairs={pairs}
//           />
//         )}
//       </div>
//     </>
//   );
// };

export default TradeChart;
