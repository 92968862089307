import { web3Instance } from "../App";
import { WALLET_DECIMAL } from "./constants";
import { Trade } from "./interfaces";

export const addressFormate = (data: string, length = 4) => {
  return `${data?.substring(0, length)}...${data?.substring(
    data.length - length,
    data.length
  )}`;
};

export const amountFormate = (amount: string, decimal: number, toFixed: number) => {
  if (Number(amount) > 0) {
    const newAmount = Number(amount) / 10 ** decimal;
    return Math.floor(Number(newAmount) * 10 ** toFixed) / 10 ** toFixed;
  }
  return 0;
};

export const formatValueFromWei = (balance: string | number) => {
  if (!balance || !web3Instance) return "0"
  return web3Instance.utils.fromWei(balance.toString(), 'ether');
}

export const formatValueToWei = (balance: string | number) => {
  if (!balance || !web3Instance) return "0"
  return web3Instance.utils.toWei(balance.toString(), 'ether');
}

export const fixToDecimals = (value: string | number , decimal: number) => {
  return Number(value).toFixed(decimal).replace(/\.?0+$/,"");
}

export const tradeValueFormate = (value, decimal) => {
  if (!value || value <= 0 || value === undefined) {
    return 0;
  } else {
    return value / 10 ** decimal;
  }
};

export const formatBalance = (value: string) => {
  let newValue = value;
  let abc = "";
  const integer = String(value).split('.')[0].length;

  if (integer > 3 && integer < 7) {
    abc = "K";
    newValue = `${amountFormate(value, 3, WALLET_DECIMAL)}`;
  } else if (integer > 6 && integer < 10) {
    abc = "M";
    newValue = `${amountFormate(value, 6, WALLET_DECIMAL)}`;
  } else if (integer > 9 && integer < 13) {
    abc = "B";
    newValue = `${amountFormate(value, 9, WALLET_DECIMAL)}`;
  } else if (integer > 12) {
    abc = "T";
    newValue = `${amountFormate(value, 12, WALLET_DECIMAL)}`;
  } else {
    newValue = (Math.floor(Number(newValue) * 10 ** WALLET_DECIMAL) / 10 ** WALLET_DECIMAL).toString();
  }
  return `${newValue}${abc}`
}

interface BarsData {
  l: Array<string>,
  o: Array<string>,
  c: Array<string>,
  h: Array<string>,
  t: Array<string>,
  v: Array<string>,
}
export const formatToBars = (trades: Trade[]) => {
  return trades.reduce((acc, t) => {
    acc.l.push(t.price);
    acc.o.push(t.price);
    acc.c.push(t.price);
    acc.h.push(t.price);
    acc.t.push(t.timestamp);
    acc.v.push(t.volume);
    return acc
  }, {} as BarsData)
}